import {Injectable} from '@angular/core';
import {Bateau} from "../models/Bateau";
import {HttpClient} from "@angular/common/http";
import {LogService} from "./Log.service";
import {API} from "../enums/API";
import {Ligne} from "../models/Ligne";
import {Port} from "../models/Port";


@Injectable()

export class BateauService {
    public bateaux : Bateau[];
    public lignes : Ligne[];
    public ports : Port[];

    constructor(private http: HttpClient,
                private logService: LogService) {
    }

    bateauxHttp() : Promise<Bateau[]> {
        this.logService.logService("bateau.service.bateauxHttp()");
        return new Promise<Bateau[]>((resolve,reject) => {
            this.http.get(API.GetBateauxUrl()+'?per_page=100').toPromise()
                .then((data: Bateau[]) => {
                    this.logService.logHttpCallback(API.GetBateauxUrl()+'?per_page=100',data);
                    this.bateaux=data;
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetBateauxUrl()+'?per_page=100');
                    reject();
                });
        });

    }

    addBateauxHttp(bateau:Bateau) : Promise<Bateau> {
        this.logService.logService("bateau.service.addBateauxHttp()");
        return new Promise<Bateau>((resolve,reject) => {
            this.http.post(API.GetBateauxUrl(),bateau).toPromise()
                .then((data: Bateau) => {
                    this.logService.logHttpCallback(API.GetBateauxUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetBateauxUrl());
                    reject();
                });
        });

    }

    editBateauxHttp(bateau:Bateau) : Promise<Bateau> {
        this.logService.logService("bateau.service.editBateauxHttp()");
        return new Promise<Bateau>((resolve,reject) => {
            this.http.put(API.GetBateauxUrl()+"/"+bateau.id,bateau).toPromise()
                .then((data: Bateau) => {
                    this.logService.logHttpCallback(API.GetBateauxUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetBateauxUrl());
                    reject();
                });
        });
    }
    deleteBateauxHttp(bateau:Bateau) : Promise<boolean> {
        this.logService.logService("bateau.service.deleteBateauxHttp()");
        return new Promise<boolean>((resolve,reject) => {
            this.http.delete(API.GetBateauxUrl()+"/"+bateau.id).toPromise()
                .then((data: boolean) => {
                    this.logService.logHttpCallback(API.GetBateauxUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetBateauxUrl());
                    reject();
                });
        });
    }

    lignesHttp() : Promise<Ligne[]> {
        this.logService.logService("bateau.service.lignesHttp()");
        return new Promise<Ligne[]>((resolve,reject) => {
            this.http.get(API.GetLignesUrl()+'?per_page=100').toPromise()
                .then((data: Ligne[]) => {
                    this.logService.logHttpCallback(API.GetLignesUrl()+'?per_page=100',data);
                    this.lignes=data;
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetLignesUrl()+'?per_page=100');
                    reject();
                });
        });

    }

    addLignesHttp(ligne:Ligne) : Promise<Ligne> {
        this.logService.logService("bateau.service.addLignesHttp()");
        return new Promise<Ligne>((resolve,reject) => {
            this.http.post(API.GetLignesUrl(),ligne).toPromise()
                .then((data: Ligne) => {
                    this.logService.logHttpCallback(API.GetLignesUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetLignesUrl());
                    reject();
                });
        });

    }

    editLignesHttp(ligne:Ligne) : Promise<Ligne> {
        this.logService.logService("bateau.service.editLignesHttp()");
        return new Promise<Ligne>((resolve,reject) => {
            this.http.put(API.GetLignesUrl()+"/"+ligne.id,ligne).toPromise()
                .then((data: Ligne) => {
                    this.logService.logHttpCallback(API.GetLignesUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetLignesUrl());
                    reject();
                });
        });
    }

    deleteLignesHttp(ligne:Ligne) : Promise<boolean> {
        this.logService.logService("bateau.service.deleteLignesHttp()");
        return new Promise<boolean>((resolve,reject) => {
            this.http.delete(API.GetLignesUrl()+"/"+ligne.id).toPromise()
                .then((data: boolean) => {
                    this.logService.logHttpCallback(API.GetLignesUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetLignesUrl());
                    reject();
                });
        });
    }

    portsHttp() : Promise<Port[]> {
        this.logService.logService("bateau.service.portsHttp()");
        return new Promise<Port[]>((resolve,reject) => {
            this.http.get(API.GetPortsUrl()+'?per_page=100').toPromise()
                .then((data: Port[]) => {
                    this.logService.logHttpCallback(API.GetPortsUrl()+'?per_page=100',data);
                    this.ports=data;
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetPortsUrl()+'?per_page=100');
                    reject();
                });
        });

    }

    addPortsHttp(port:Port) : Promise<Port> {
        this.logService.logService("bateau.service.addPortsHttp()");
        return new Promise<Port>((resolve,reject) => {
            this.http.post<Port>(API.GetPortsUrl(),port).toPromise()
                .then((data: Port) => {
                    this.logService.logHttpCallback(API.GetPortsUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetPortsUrl());
                    reject();
                });
        });

    }
    editPortsHttp(port:Port) : Promise<Port> {
        this.logService.logService("bateau.service.editPortsHttp()");
        return new Promise<Port>((resolve,reject) => {
            this.http.put(API.GetPortsUrl()+"/"+port.id,port).toPromise()
                .then((data: Port) => {
                    this.logService.logHttpCallback(API.GetPortsUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetPortsUrl());
                    reject();
                });
        });
    }

    deletePortsHttp(port:Port) : Promise<boolean> {
        this.logService.logService("bateau.service.deletePortsHttp()");
        return new Promise<boolean>((resolve,reject) => {
            this.http.delete(API.GetPortsUrl()+"/"+port.id).toPromise()
                .then((data: boolean) => {
                    this.logService.logHttpCallback(API.GetPortsUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetPortsUrl());
                    reject();
                });
        });
    }

    getBateauById(id:number){
        return this.bateaux.filter((b)=>b.id == id).shift();
    }

    getPortById(id:number){
        return this.ports.filter((b)=>b.id == id).shift();
    }

    getLigneById(id:number){
        return this.lignes.filter((b)=>b.id == id).shift();
    }
}
