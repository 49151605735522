<!--Footer-->
<footer class="page-footer center-on-small-only pt-0 mt-5">

  <!--Copyright-->
  <div class="footer-copyright text-center py-3">
    <div class="container-fluid">
      &copy; 2023 Copyright: <a target="_blank" href="https://www.synexie.fr"> Synexie </a>
    </div>
  </div>
  <!--/.Copyright-->

</footer>
<!--/.Footer-->
