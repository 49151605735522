import {Entity} from "./Entity";
import {TypeEvent} from "../enums/TypeEvent";
import {Bateau} from "./Bateau";
import {Ligne} from "./Ligne";
import {Hour} from "ng-uikit-pro-standard/lib/pro/timepicker/timepicker.interface";
import {User} from "./User";
import {DurationEvent} from "../enums/DurationEvent";

export class FcEvent extends Entity {

    typeEvent?:TypeEvent|string;
    durationEvent?:DurationEvent|string;
    heureDebut?:Hour|string;
    heureFin?:Hour|string;
    bateau?:Bateau|number;
    ligne?:Ligne|number;
    commentaire?:string;
    dateStartEvent?:Date|string;
    dateEndEvent?:Date|string;
    repeatEvent?:{lu:boolean,ma:boolean,me:boolean,je:boolean,ve:boolean,sa:boolean,di:boolean};
    employeRef?:User|number;
    employeRefComment?:string;
    adminComment?:string;
    employeRefValidation?:boolean;
    employes?:{employe:User|number,time:number}[];
    legende? : {name:string,value:boolean,acronym:string}[];

    constructor(){
        super();
        this.employes = [];
        this.employeRef = -1;
        this.typeEvent = "NAVIGATION";
        this.durationEvent = "";
        this.repeatEvent = {lu:false,ma:false,me:false,je:false,ve:false,sa:false,di:false};
        this.setDefaultLegend();
    }

    getEmployesUsers(): User[]{
        return this.employes.map((e)=>{return e.employe as User});
    }

    getEmployesIds(): number[]{
        return this.employes.map((e)=>{return e.employe as number});
    }

    setDefaultLegend():void{
      this.legende = this.legende ? this.legende : [
        {name:"Lessivage",value:false,acronym:"L"},
        {name:"Prestation",value:false,acronym:"PR"},
        {name:"Port-Cros",value:false,acronym:"PC"},
        {name:"Affretement",value:false,acronym:"AF"},
        {name:"Mecanique",value:false,acronym:"M"},
        {name:"18h45",value:false,acronym:"R+"},
        {name:"11h30",value:false,acronym:"A+"},
        {name:"16h15",value:false,acronym:"A+"},
        {name:"Croisiere",value:false,acronym:"CR"},
        {name:"Escapade",value:false,acronym:"ESC"},
        {name:"Feu d'artifice",value:false,acronym:"F"},
        {name:"Feu d'artifice Vigneronne",value:false,acronym:"FV"},
        {name:"Vigneronne",value:false,acronym:"VG"},
        {name:"Shuttle",value:false,acronym:"SH"},
        {name:"Divers",value:false,acronym:"D"},
        {name:"Veille Capitaine",value:false,acronym:"V"}
      ]
    }
}
