
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationComponent } from './navigation.component';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MDBBootstrapModulesPro.forRoot(),
        SharedModule
    ],
  declarations: [
    NavigationComponent,
  ],
  exports: [
    NavigationComponent
  ],
  providers: []
})
export class NavigationModule {

}
