import {ToastModule} from 'ng-uikit-pro-standard';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {ViewsModule} from './views/views.module';
import {SharedModule} from './shared/shared.module';
import {MDBSpinningPreloader} from 'ng-uikit-pro-standard';
import {AgmCoreModule} from '@agm/core';
import {ErrorModule} from './views/errors/error.module';

// main layout
import {NavigationModule} from './main-layout/navigation/navigation.module';
import {FcViewsModule} from "./fc-views/fc-views.module";
import {FullCalendarModule} from "@fullcalendar/angular";

//Calendar
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import adaptivePlugin from '@fullcalendar/adaptive'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import {AuthenticationService} from "../providers/Authentication.service";
import {TokenStorageService} from "../providers/TokenStorage.service";
import {
    AUTH_SERVICE,
    PROTECTED_FALLBACK_PAGE_URI,
    ProtectedGuard,
    PUBLIC_FALLBACK_PAGE_URI,
    PublicGuard
} from "ngx-auth";
import {LogService} from "../providers/Log.service";
import {BateauService} from "../providers/Bateau.service";
import {LoggerModule, NGXLogger, NgxLoggerLevel} from "ngx-logger";
import {UserService} from "../providers/User.service";
import {AuthenticationInterceptor} from "../interceptors/Authentication.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {EventService} from "../providers/Event.service";
import {ConfigService} from "../providers/Config.service";


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    adaptivePlugin
]);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NavigationModule,
        AppRoutingModule,
        FormsModule,
        SharedModule,
        ViewsModule,
        FcViewsModule,
        ErrorModule,
        ToastModule.forRoot(),
        ReactiveFormsModule,
        LoggerModule.forRoot({
            serverLoggingUrl: null,
            level: NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.ERROR,
            timestampFormat:"yyyy-MM-dd HH:mm:ss.SSS"
        }),
        AgmCoreModule.forRoot({
            apiKey: 'your key here'
        }),
        FullCalendarModule // register FullCalendar with you app
    ],
    providers: [
        MDBSpinningPreloader,
        AuthenticationService,
        TokenStorageService,
        ProtectedGuard,
        PublicGuard,
        LogService,
        BateauService,
        UserService,
        EventService,
        ConfigService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
        { provide: AUTH_SERVICE, useClass: AuthenticationService }
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
