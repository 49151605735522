import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import {ModalDirective} from "ng-uikit-pro-standard";
import frLocale from '@fullcalendar/core/locales/fr';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {

  public calendarGlobal: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale:frLocale,
    events: [
      { title: 'Vue globale', start  : '2021-02-20T12:30:00', allDay : false, end: '2021-02-20T13:30:00'
      }
    ]
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}
