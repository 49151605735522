import {Injectable} from '@angular/core';
import {Bateau} from "../models/Bateau";
import {HttpClient} from "@angular/common/http";
import {LogService} from "./Log.service";
import {API} from "../enums/API";
import {Ligne} from "../models/Ligne";
import {User} from "../models/User";
import {FcEvent} from "../models/FcEvent";
import {UserService} from "./User.service";
import {ToastService} from "ng-uikit-pro-standard";
import * as moment from 'moment';
import 'moment/locale/fr';

@Injectable()

export class ConfigService {


    constructor() {}


    getLocales(){
        return {
            'fr': {
                dayLabels: { su: 'Dim', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Jeu', fr: 'Ven', sa: 'Sam' },
                dayLabelsFull: { su: 'Dimanche', mo: 'Lundi', tu: 'Mardi', we: 'Mercredi', th: 'Jeudi', fr: 'Vendredi', sa: 'Dimanche' },
                monthLabels: {
                    1: 'Jan', 2: 'Fev', 3: 'Mar', 4: 'Avr', 5: 'Mai', 6: 'Jun',
                    7: 'Jul', 8: 'Aou', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
                },
                monthLabelsFull: {
                    1: 'Janvier', 2: 'Février', 3: 'Mars', 4: 'Avril', 5: 'Mai', 6: 'Juin', 7: 'Juillet',
                    8: 'Aout', 9: 'Septembre', 10: 'Octobre', 11: 'Novembre', 12: 'Décembre'
                },
                dateFormat: 'dd mmmm yyyy',
                todayBtnTxt: 'Aujourd\'hui',
                clearBtnTxt: 'Effacer',
                closeBtnTxt: 'Fermer',
                firstDayOfWeek: 'mo',
                sunHighlight: true,
            }
        };
    }

}
