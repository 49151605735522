import {Component, OnInit, ViewChild} from '@angular/core';
import {Bateau} from "../../../models/Bateau";
import {CalendarOptions, FullCalendarComponent} from "@fullcalendar/angular";
import frLocale from "@fullcalendar/core/locales/fr";
import {BateauService} from "../../../providers/Bateau.service";
import {LocaleService} from "ng-uikit-pro-standard";
import {UserService} from "../../../providers/User.service";
import {EventService} from "../../../providers/Event.service";
import * as moment from "moment";
import {User} from "../../../models/User";
import {TypeEvent} from "../../../enums/TypeEvent";
import {FcEvent} from "../../../models/FcEvent";

@Component({
  selector: 'app-heures-supp',
  templateUrl: './heures-supp.component.html',
  styleUrls: ['./heures-supp.component.scss']
})
export class HeuresSuppComponent implements OnInit {

  bateaux: Bateau[];
  users: User[];
  selectedBateau:Bateau;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public calendarOptions: CalendarOptions = {
    schedulerLicenseKey: null,
    initialView: 'resourceTimelineWeek',
    locale:frLocale,
    allDaySlot : true,
    filterResourcesWithEvents: true,
    slotDuration: {days: 1},
    slotLabelFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
    resourceAreaWidth:200,
    dayHeaderFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
    resourceAreaHeaderContent: "Employés"
  };

  constructor( public bateauService : BateauService,
               public eventService : EventService,
               public userService : UserService) { }

  ngOnInit(): void {
    const me = this;
    this.getUsers().then(()=>{
      this.calendarOptions.resourceOrder ='title,id';
      this.calendarOptions.resources = function (info, successCallback, failureCallback) {
        successCallback(
          me.users.map(function(user) {
            return {
              id: user.id.toString(),
              title: user.last_name.toUpperCase()+ " " + user.first_name
            }
          })
        );
      }
    })

    this.getBateaux().then(() => {
      this.selectedBateau = null;
      this.calendarOptions.events = function(info, successCallback, failureCallback) {
        const eventArgs = {
          'start':info.start.valueOf(),
          'end':info.end.valueOf(),
          'type': me.selectedBateau ? TypeEvent.Navigation : TypeEvent.All,
          'user': null,
          'bateau':me.selectedBateau ? me.selectedBateau.id : null};
        me.eventService.eventsHttp(eventArgs).then(
          (events)=>{
            successCallback(
              events.map(function(eventEl) {
                let start = moment(eventEl.dateStartEvent+' '+ eventEl.heureDebut, "YYYY-MM-DD hh:mm").toISOString();
                let end = moment(eventEl.dateEndEvent+' '+ eventEl.heureFin, "YYYY-MM-DD hh:mm").toISOString();
                return {
                  start: start,
                  end: end,
                  allDay : true,
                  resourceIds: eventEl.employes.map((u)=>{return u.employe.toString()}),
                  extendedProps : eventEl,
                  id: eventEl.id.toString()
                }
              })
            )
          }
        )
      };
    });
    this.calendarOptions.eventDidMount  = (args) => {
      let eventId = args.event.id;
      let userId = args.el.closest("[data-resource-id]").getAttribute("data-resource-id");
      let fcEvent = args.event.extendedProps;
      let employe = fcEvent.employes.find((e)=>e.employe == userId);
      let time = employe ? employe.time : 0;
      let ref = (this.eventService.isEventNeedReferent(fcEvent as FcEvent) && employe.employe == fcEvent.employeRef ? '<i class="fa fa-star"></i> ':'');
      let legend = fcEvent.legende.filter(l=>l.value).map((l)=>l.acronym).join(" - ");
      legend = legend ? "("+legend+")":"";
      let comment = fcEvent.employeRefComment ? '<div class="faircrew-heure-supp-comment">'+fcEvent.employeRefComment+'</div>' : '';
      let validedClass = comment.toLowerCase().indexOf("ok fafa") >=0 ? "<i class='fa fa-check text-success'></i>"  : ""
      let string = '<div class="faircrew-heure-supp" style="text-align: center; width: 100%">' +
        (this.eventService.isEventNeedHeuresSupp(fcEvent as FcEvent) ?'<div>'+time+'H '+ref+' '+legend+' '+validedClass+'</div>':'') +
        ('<small>'+fcEvent.typeEvent+'</small>') +
        (this.selectedBateau != null && !legend  ? '<div style="font-size: 10px">'+comment+'</div>':'');

      args.el.style.background = "none";
      args.el.style.border = "none";
      args.el.innerHTML = string;
    }
  }

  refreshResources(){
    this.calendarComponent.getApi().refetchEvents();
  }

  getBateaux(){
    return this.bateauService.bateauxHttp().then((bateaux)=>{
      this.bateaux = bateaux;
    });
  }

  getUsers(){
    return this.userService.usersHttp().then((users)=>{
      this.users = users;
    });
  }



}
