import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../../providers/Authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public usertitle: string;
  public password: string;
  public isloging: boolean;
  public error: string;

  constructor(public router: Router, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isloging = false;
    this.usertitle = '';
    this.password = '';
    this.error = '';
  }


  public login() {
    if(!this.isloging){
      this.isloging = true;
      if(this.usertitle != '' && this.password != ''){
        this.authService.login(this.usertitle,this.password).toPromise()
            .then((data)=>{
              if(data.statusCode == 200){
                this.router.navigateByUrl('/');
              } else{
                this.error = data.message;
              }

            }).catch((error)=>{
          this.error = "Email ou mot de passe incorrect."
        }).finally(()=>{
          this.isloging = false;
        });
      } else{
        setTimeout(()=>{
          this.isloging = false;
        },5000);
      }
    }
  }

}
