import { BateauxComponent } from './fc-views/bateaux/bateaux.component';
import { NotFoundComponent } from './views/errors/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import {EquipagesComponent} from "./fc-views/equipages/equipages.component";
import {PlanningComponent} from "./fc-views/planning/planning.component";
import {ProfileComponent} from "./fc-views/profile/profile.component";
import {GlobalComponent} from "./fc-views/global/global.component";
import {SettingsComponent} from "./fc-views/settings/settings.component";
import {LoginComponent} from "./fc-views/login/login.component";
import {ProtectedGuard, PublicGuard} from "ngx-auth";
import {HeuresSuppComponent} from "./fc-views/heures-supp/heures-supp.component";
import {CongesComponent} from "./fc-views/conges/conges.component";

const routes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'planning' },
  { path: 'planning', component: PlanningComponent,canActivate: [ ProtectedGuard ]},
  { path: 'bateaux', component: BateauxComponent,canActivate: [ ProtectedGuard ]},
  { path: 'equipages', component: EquipagesComponent,canActivate: [ ProtectedGuard ]},
  { path: 'profile', component: ProfileComponent,canActivate: [ ProtectedGuard ]},
  { path: 'global', component: GlobalComponent,canActivate: [ ProtectedGuard ]},
  { path: 'settings', component: SettingsComponent,canActivate: [ ProtectedGuard ]},
  { path: 'login', component: LoginComponent, canActivate: [ PublicGuard ]},
  { path: 'heures-supp', component: HeuresSuppComponent, canActivate: [ ProtectedGuard ]},
  { path: 'conges', component: CongesComponent, canActivate: [ ProtectedGuard ]},
  { path: '**', component: NotFoundComponent,canActivate: [ ProtectedGuard ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

/*{
    path: 'dashboards',
    children: [
      { path: 'v1', component: Dashboard1Component },
      { path: 'v2', component: Dashboard2Component },
      { path: 'v3', component: Dashboard3Component },
      { path: 'v4', component: Dashboard4Component },
      { path: 'v5', component: Dashboard5Component },
    ],
  },

  {
    path: 'pages',
    children: [
      //{ path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'lock', component: LockComponent },
      { path: 'pricing', component: PricingComponent },
      { path: 'single-post', component: SinglePostComponent },
      { path: 'post-listing', component: PostListingComponent },
      { path: 'customers', component: CustomersComponent },
    ],
  },
  {
    path: 'profiles',
    children: [
      { path: 'profile1', component: Profile1Component },
      { path: 'profile2', component: Profile2Component },
      { path: 'profile3', component: Profile3Component },
    ],
  },
  {
    path: 'settings',
    children: [
      { path: 'settings1', component: Settings1Component },
      { path: 'settings2', component: Settings2Component },
      { path: 'settings3', component: Settings3Component },
    ],
  },
  {
    path: 'components',
    children: [
      { path: 'buttons', component: ButtonsComponent },
      { path: 'cards', component: CardsComponent },
      { path: 'panels', component: PanelsComponent },
      { path: 'lists', component: ListsComponent },
      { path: 'pagination', component: PaginationComponent },
      { path: 'progress-bars', component: ProgressBarsComponent },
      { path: 'tabs', component: TabsComponent },
      { path: 'tags', component: TagsComponent },
      { path: 'collapse', component: CollapseComponent },
      { path: 'date-picker', component: DatePickerComponent },
      { path: 'time-picker', component: TimePickerComponent },
      { path: 'tooltips', component: TooltipsComponent },
      { path: 'popovers', component: PopoversComponent },
    ],
  },
  {
    path: 'tables',
    children: [
      { path: 'table1', component: BasicTableComponent },
      { path: 'table2', component: Table2Component },
    ],
  },
  {
    path: 'charts',
    children: [
      { path: 'chart1', component: Chart1Component },
      { path: 'chart2', component: Chart2Component },
      { path: 'chart3', component: Chart3Component },
    ],
  },
  {
    path: 'maps',
    children: [
      { path: 'map1', component: Map1Component },
      { path: 'map2', component: Map2Component },
      { path: 'map3', component: Map3Component },
    ],
  },
  {
    path: 'css',
    children: [
      { path: 'grid', component: GridComponent },
      { path: 'media', component: MediaObjectComponent },
      { path: 'utilities', component: UtilitiesComponent },
      { path: 'icons', component: IconsComponent },
      { path: 'images', component: ImagesComponent },
      { path: 'typography', component: TypographyComponent },
      { path: 'colors', component: ColorsComponent },
      { path: 'shadow', component: ShadowComponent },
    ],
  },
  {
    path: 'forms',
    children: [
      { path: 'form1', component: Form1Component },
      { path: 'form2', component: Form2Component },
      { path: 'form3', component: Form3Component },
    ],
  },
  { path: 'alerts', component: AlertComponent },
  { path: 'modals', component: ModalsComponent },
  { path: 'calendar', component: EventCalendarComponent },
  { path: 'help', component: HelpComponent },
  { path: 'sections', component: SectionsComponent },
  { path: 'test', component: TestComponent },*/
