import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LogService} from "./Log.service";
import {API} from "../enums/API";
import {FcEvent} from "../models/FcEvent";
import {ToastService} from "ng-uikit-pro-standard";
import * as moment from 'moment';
import 'moment/locale/fr';
import {TypeEvent} from "../enums/TypeEvent";

@Injectable()

export class EventService {
    public events: FcEvent[];
    typesEventReferent = [TypeEvent.Presta, TypeEvent.Escale, TypeEvent.Navigation, TypeEvent.Chantier];
    typesEventHeuresSupp = [
      TypeEvent.Presta,
      TypeEvent.Escale,
      TypeEvent.Navigation,
      TypeEvent.Chantier
    ];

    constructor(private http: HttpClient,
                private logService: LogService,
                private toast: ToastService
    ) {
    }


    eventsHttp(args: any): Promise<FcEvent[]> {
        this.logService.logService("event.service.eventsHttp()");
        return new Promise<FcEvent[]>((resolve, reject) => {
            this.http.get(API.GetEventsWithParamsUrl(), {params: args}).toPromise()
                .then((data: FcEvent[]) => {
                    this.logService.logHttpCallback(API.GetEventsWithParamsUrl(), data);
                    this.events = data.map((e)=>{
                      let event = Object.assign(new FcEvent(),e);
                      event.setDefaultLegend();
                      return event;
                    });
                    resolve(this.events);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetEventsWithParamsUrl());
                    this.toast.error("Problème lors de la récupération des événements");
                    reject();
                });
        });
    }

    addEventHttp(event: FcEvent): Promise<FcEvent> {
        this.logService.logService("event.service.addEventHttp()");
        event.title = this.setEventTitle(event);
        event.dateEndEvent = this.formatDateEnd(event);
        event.dateStartEvent = this.formatDateStart(event);
        return new Promise<FcEvent>((resolve, reject) => {
            this.http.post(API.GetEventsUrl(), event).toPromise()
                .then((data: FcEvent) => {
                    this.logService.logHttpCallback(API.GetEventsUrl(), data);
                    this.toast.success("Evenement correctement ajouté");
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetEventsUrl());
                    this.toast.error("Problème lors de l'ajout de l'événement");
                    reject();
                });
        });

    }

    editEventHttp(event: FcEvent): Promise<FcEvent> {
        this.logService.logService("event.service.editEventHttp()");
        event.title = this.setEventTitle(event);
        event.dateEndEvent = this.formatDateEnd(event);
        event.dateStartEvent = this.formatDateStart(event);
        return new Promise<FcEvent>((resolve, reject) => {
            this.http.put(API.GetEventsUrl() + "/" + event.id, event).toPromise()
                .then((data: FcEvent) => {
                    this.logService.logHttpCallback(API.GetEventsUrl(), data);
                    this.toast.success("Evenement correctement édité");
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetEventsUrl());
                    this.toast.error("Problème lors de la sauvegarde de l'événement");
                    reject();
                });
        });
    }

    deleteEventHttp(event: FcEvent): Promise<boolean> {
        this.logService.logService("user.service.deleteEventHttp()");
        return new Promise<boolean>((resolve, reject) => {
            this.http.delete(API.GetEventsUrl() + "/" + event.id).toPromise()
                .then((data: boolean) => {
                    this.logService.logHttpCallback(API.GetEventsUrl(), data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetEventsUrl());
                    this.toast.error("Problème lors de la supression de l'événement");
                    reject();
                });
        });
    }

  heuresSuppHttp(args: any): Promise<any[]> {
    this.logService.logService("event.service.heuresSuppHttp()");
    return new Promise<FcEvent[]>((resolve, reject) => {
      this.http.get(API.GetHeuresSuppWithParamsUrl(), {params: args}).toPromise()
        .then((data: FcEvent[]) => {
          this.logService.logHttpCallback(API.GetHeuresSuppWithParamsUrl(), data);
          resolve(data);
        })
        .catch(() => {
          this.logService.logHttpCallbackError(API.GetHeuresSuppWithParamsUrl());
          this.toast.error("Problème lors de la récupération des heures supplémentaires");
          reject();
        });
    });
  }

  sendPlanningHttp(dateStart: string, dateEnd: string): Promise<boolean> {
    this.logService.logService("event.service.sendPlanningHttp()");
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(API.GetSendPlanningUrl(), {params: {start:dateStart,end:dateEnd}}).toPromise()
        .then((data: boolean) => {
          this.logService.logHttpCallback(API.GetSendPlanningUrl(), data);
          resolve(data);
        })
        .catch(() => {
          this.logService.logHttpCallbackError(API.GetSendPlanningUrl());
          this.toast.error("Problème lors de l'envoi du planning");
          reject();
        });
    });
  }

    isEventNeedReferent(event:FcEvent){
      return this.typesEventReferent.includes(event.typeEvent as TypeEvent);
    }

    isEventNeedHeuresSupp(event:FcEvent){
      return this.typesEventHeuresSupp.includes(event.typeEvent as TypeEvent);
    }

    setEventTitle(event:FcEvent){
        return event.dateStartEvent+" "+event.heureDebut +"-"+event.dateEndEvent+" "+event.heureFin;
    }

    formatDateStart(event:FcEvent){
        return moment(event.dateStartEvent, "DD/MM/YYYY").format('YYYY-MM-DD');
    }

    formatDateEnd(event:FcEvent){
        return moment(event.dateStartEvent, "DD/MM/YYYY").format('YYYY-MM-DD');
    }

}
