import {Component, OnInit, ViewChild} from '@angular/core';
import {IMyOptions, ModalDirective, ToastService} from "ng-uikit-pro-standard";
import {CalendarOptions} from '@fullcalendar/angular'; // useful for typechecking
import frLocale from '@fullcalendar/core/locales/fr';
import {Equipage} from "../../../models/Equipage";
import {Fonction} from "../../../enums/Fonction";
import {Contrat} from "../../../enums/Contrat";
import {UserService} from "../../../providers/User.service";
import {BateauService} from "../../../providers/Bateau.service";
import {Ligne} from "../../../models/Ligne";
import {User} from "../../../models/User";
import {Port} from "../../../models/Port";

@Component({
  selector: 'app-equipages',
  templateUrl: './equipages.component.html',
  styleUrls: ['./equipages.component.scss']
})


export class EquipagesComponent implements OnInit {


  public checkModel: any = { left: true, middle: false, right: false };

  employeShow:User;
  employeEdited:User;
  employeDelete:User;
  employes: User[];
  employesArchived: User[];
  ports: Port[];

  public datePickerBirthOptions : IMyOptions = {
    minYear: 1900
  };


  public calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale: frLocale,
    events: [
      { title: 'bateau 1 ', start  : '2021-02-20T12:30:00', allDay : false, end: '2021-02-20T13:30:00'}
    ]
  };
  public checkCongesEmploye: any = { lu: false, ma: false, me: false, je: false, ve: false, sa: false, di: false };



  public showOnlyFonctionSelect: object[] = [
    { value: Fonction.Administratif, label: Fonction.Administratif },
    { value: Fonction.Marin, label: Fonction.Marin },
    { value: Fonction.Mecanicien, label: Fonction.Mecanicien }
  ];


  public showOnlyContratSelect: object[] = [
    { value: Contrat.Cdd, label: Contrat.Cdd },
    { value: Contrat.Cdi, label: Contrat.Cdi },
    { value: Contrat.Alternance, label: Contrat.Alternance },
    { value: Contrat.Stage, label: Contrat.Stage},
    { value: Contrat.Interim, label: Contrat.Interim },
  ];

  @ViewChild('modalEditEmploye') modalEditEmploye: ModalDirective;
  @ViewChild('modalShowEmploye') modalShowEmploye: ModalDirective;
  @ViewChild('modalDeleteEmploye') modalDeleteEmploye: ModalDirective;
  @ViewChild('modalEditEquipage') modalEditEquipage: ModalDirective;
  @ViewChild('modalShowEquipage') modalShowEquipage: ModalDirective;
  @ViewChild('modalDeleteEquipage') modalDeleteEquipage: ModalDirective;
  @ViewChild('modalShowArchives') modalShowArchives: ModalDirective;

  private sorted = false;

  constructor(public userService : UserService, public bateauService:BateauService,private toast: ToastService) {  }

  ngOnInit(): void {
    this.getUsers();
    this.getPorts();

  }

  getPorts(){
    this.bateauService.portsHttp().then((ports)=>{
      this.ports = ports;
    });
  }

  getUsers(){
    this.userService.usersHttp().then((users)=>{
      this.employes = users.filter(user=>user.userRole === "employe");
    })
  }

  getInactifUsers(){
    return this.employes.filter((e)=>!e.actif);
  }

  editEmploye(employe:User=null){
      if (employe ==null){
          employe=new User();
      }
    this.employeEdited=employe;
    this.modalEditEmploye.config.data.employeEdit=JSON.parse(JSON.stringify(employe));
    this.modalEditEmploye.show();
  }
  deleteEmploye(employe:User){
    this.employeDelete=employe;
    this.modalDeleteEmploye.show();
  }
  onDeleteEmploye(){
    this.userService.deleteUserHttp(this.employeDelete).then(()=>{
      this.toast.success("Collaborateur correctement supprimé !");
      this.modalDeleteEmploye.hide();
      this.getUsers();
    }).catch(()=>{
      this.toast.error("Erreur lors de la suppression du collaborateur...");
    });
  }
  toggleActif(employe:User=null){
    employe.actif = !employe.actif;
    this.userService.editUserHttp(employe).then(()=>{
      this.getUsers();
      if(employe.actif){
        this.toast.success("Collaborateur ré-activé !");
      } else {
        this.toast.success("Collaborateur désactivé !");
      }
    }).catch(()=>{
      this.toast.error("Erreur lors de la mise à jour du statut du collaborateur...");
    })
  }
  saveEditEmploye(){
    this.employeEdited=this.modalEditEmploye.config.data.employeEdit;
    if(!this.employeEdited.id){
      if(this.employeEdited.first_name &&
          this.employeEdited.last_name &&
          this.employeEdited.mail){
        this.employeEdited.username = this.employeEdited.mail;
        this.employeEdited.email = this.employeEdited.mail;
        this.employeEdited.userRole = 'employe';
        this.employeEdited.actif = true;
        this.userService.addUserHttp(this.employeEdited).then(()=>{
          this.getUsers();
          this.toast.success("Collaborateur correctement ajouté !");
        }).catch(()=>{
          this.toast.error("Problème lors de l'ajout du collaborateur...");
        }).finally(()=>{
          this.modalEditEmploye.hide();
        });
      }

    }
    else{
      this.employeEdited=this.modalEditEmploye.config.data.employeEdit;
      this.userService.editUserHttp(this.employeEdited).then(()=>{
        this.getUsers();
        this.modalEditEmploye.hide();
        this.toast.success("Collaborateur correctement édité !");
      }).catch(()=>{
        this.toast.error("Problème lors de l'édition du collaborateur...");
      }).finally(()=>{
        this.modalEditEmploye.hide();
      });
    }

  }


  sortBy(by: string | any): void {

    this.employes.sort((a: any, b: any) => {
      if (a[by] < b[by]) {
        return this.sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return this.sorted ? -1 : 1;
      }

      return 0;
    });

    this.sorted = !this.sorted;
  }
  showEmploye(employe:User){
    this.employeShow=employe;
    this.modalShowEmploye.show();
  }

}
