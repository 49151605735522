import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import {IMyOptions, LocaleService, ModalDirective, ToastService} from "ng-uikit-pro-standard";
import frLocale from '@fullcalendar/core/locales/fr';
import {BateauService} from "../../../providers/Bateau.service";
import {EventService} from "../../../providers/Event.service";
import {Bateau} from "../../../models/Bateau";
import {Ligne} from "../../../models/Ligne";
import {FcEvent} from "../../../models/FcEvent";
import {User} from "../../../models/User";
import {UserService} from "../../../providers/User.service";
import * as moment from 'moment';
import 'moment/locale/fr';
import {FcEventComponent} from "../../fc-components/fc-event/fc-event.component";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {ConfigService} from "../../../providers/Config.service";
import {TypeEvent} from "../../../enums/TypeEvent";
import {DurationEvent} from "../../../enums/DurationEvent";
import tippy from "tippy.js";

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {
  typeEvent = TypeEvent;
  durationEvent = DurationEvent;
  bateaux: Bateau[];
  lignes: Ligne[];
  employes: User[];
  employesUsed: number[] = [];
  events: FcEvent[];
  eventEdited:FcEvent;
  eventDelete:FcEvent;
  searchEmployesText:string;
  eventType:string = 'ALL';
  eventBateau:number = 0;
  eventUser:number = 0;
  eventTypeSelected : any;
  dateStartPlanning : string;
  dateEndPlanning : string;
  isSentPlanning: boolean;
  datePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy'
  };


  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public calendarOptions: CalendarOptions = {
    schedulerLicenseKey: null,
    initialView: window.innerWidth>1024 ?'dayGridWeek':'dayGridDay',
    locale:frLocale,
    contentHeight:'auto',
    allDaySlot : true,
    slotMinTime : '05:00:00'
  };

  @ViewChild('modalEditEvent') modalEditEvent: ModalDirective;
  @ViewChild('modalDeleteEvent') modalDeleteEvent: ModalDirective;
  @ViewChild('modalPlanningEvent') modalPlanningEvent: ModalDirective;

  @ViewChild('calendarScreen') screen: ElementRef;
  @ViewChild('calendarCanvas') canvas: ElementRef;
  @ViewChild('calendarDownloadLink') downloadLink: ElementRef;


  constructor(
      public eventService : EventService,
      public bateauService : BateauService,
      private localeService: LocaleService,
      public userService : UserService,
      public configService : ConfigService,
      private toast: ToastService) {
    this.localeService.setLocaleOptions(this.configService.getLocales());
  }

  ngOnInit(): void {
    const me = this;
    this.eventTypeSelected = this.typeEvent.All;
    this.getBateaux();
    this.getLignes();
    this.getEmployes().then(()=>{
      this.calendarOptions.events = function(info, successCallback, failureCallback) {
        const eventArgs = {
          'start':info.start.valueOf(),
          'end':info.end.valueOf(),
          'type':me.eventType,
          'bateau':me.eventBateau != 0 ? me.eventBateau : null,
          'user':me.eventUser!= 0 ? me.eventUser : null};
        if(me.userService.user.userRole != 'admin'){
          eventArgs.user = me.userService.user.id;
        }
        me.eventService.eventsHttp(eventArgs).then(
            (events)=>{
              successCallback(
                  events.map(function(eventEl) {
                    let start = moment(eventEl.dateStartEvent+' '+ eventEl.heureDebut, "YYYY-MM-DD hh:mm").toISOString();
                    let end = moment(eventEl.dateEndEvent+' '+ eventEl.heureFin, "YYYY-MM-DD hh:mm").toISOString();
                    let color= '#4285f4';
                    if(eventEl.typeEvent == me.typeEvent.Repos) color = "#ff5b5b";
                    if(eventEl.typeEvent == me.typeEvent.Chantier) color = "#8859ff";
                    if(eventEl.typeEvent == me.typeEvent.EntretienBateau) color = "#7040f0";
                    if(eventEl.typeEvent == me.typeEvent.Direction) color = "#8a8a8a";
                    if(eventEl.typeEvent == me.typeEvent.Formation) color = "#dedede";
                    if(eventEl.typeEvent == me.typeEvent.Ecole) color = "#bbbbbb";
                    if(eventEl.typeEvent == me.typeEvent.Reunion) color = "#989898";
                    if(eventEl.typeEvent == me.typeEvent.CaisseLaLonde) color = "#ea8d00";
                    if(eventEl.typeEvent == me.typeEvent.CaisseLaRade) color = "#ff9600";
                    if(eventEl.typeEvent == me.typeEvent.CaisseToulon) color = "#d57c00";
                    if(eventEl.typeEvent == me.typeEvent.Conges) color = "#718a00";
                    if(eventEl.typeEvent == me.typeEvent.Maladie) color = "#00a699";
                    if(eventEl.typeEvent == me.typeEvent.RS) color = "#c760d7";
                    if(eventEl.typeEvent == me.typeEvent.Affretement) color = "#7e42bd";
                    if(eventEl.typeEvent == me.typeEvent.Recuperation) color = "#00bbda";
                    if(eventEl.typeEvent == me.typeEvent.ReposAnticipe) color = "#00bbda";
                    if(eventEl.typeEvent == me.typeEvent.Prospectus) color = "#b4b4b4";
                    if(eventEl.typeEvent == me.typeEvent.FinContrat) color = "#8a2525";
                    if(eventEl.typeEvent == me.typeEvent.Quai) color = "#733bff";
                    if(me.eventService.isEventNeedReferent(eventEl)){
                      if(me.userService.isEmployeRef(eventEl)) color = "#c98736";
                      if(me.userService.isEmployeRefValidation(eventEl)) color = "#3fc948";
                      if(me.userService.isEmployeRefValidationLock(eventEl)) color = "#909090";
                    }

                    return {
                      start: start,
                      end: end,
                      allDay : true,
                      extendedProps : eventEl,
                      id: eventEl.id.toString(),
                      color:color,
                      backgroundColor: color
                    }
                  })
              )
            }
        )
      };
    });
    this.calendarOptions.eventClick = function(info) {
      const fcEvent =  Object.assign(new FcEvent(), info.event.extendedProps);
      fcEvent.dateStartEvent = moment(fcEvent.dateStartEvent, "YYYY-MM-DD").format('DD/MM/YYYY').toString();
      fcEvent.dateEndEvent = moment(fcEvent.dateEndEvent, "YYYY-MM-DD").format('DD/MM/YYYY').toString();
      fcEvent.employes.forEach((u)=>{ u.employe = (Number.isInteger(u.employe) ? me.userService.populateUsersFromIds([u.employe as number]).shift() : u.employe)});
      me.editEvent(fcEvent);
    };

    this.calendarOptions.eventDidMount = (args) => {
      const fcEvent = Object.assign(new FcEvent(), args.event.extendedProps);
      const commentaires = [];
      if(this.userService.canShowCommentaireRef() || this.userService.canShowCommentaire()){
        let commentaireRef = this.userService.canShowCommentaireRef() && fcEvent.employeRefComment ? '<strong>Ref.</strong>: '+fcEvent.employeRefComment : "";
        if(commentaireRef) commentaires.push(commentaireRef);
        let commentaire = this.userService.canShowCommentaire() && fcEvent.commentaire ? '<strong>Com.</strong>: '+fcEvent.commentaire : "";
        if(commentaire) commentaires.push(commentaire);
        let commentaireAdmin = this.userService.isAdmin() && fcEvent.adminComment? '<strong class="text-warning">Adm.</strong>: '+ fcEvent.adminComment : "";
        if(commentaireAdmin) commentaires.push(commentaireAdmin);
        if(commentaires.length>0){
          var tooltip = tippy(args.el, {
            content : commentaires.join('<br/>'),
            allowHTML : true
          });
        }
      }

    }

    this.calendarOptions.eventContent = (args) => {
      const fcEvent = Object.assign(new FcEvent(), args.event.extendedProps);
      const legend = fcEvent.legende.filter(l=>l.value).map((l)=>l.acronym).join(" - ");
      let string = '';
      string += '<div class="faircrew-event-header">';
      string += '<div>';
      string += '<strong class="faircrew-event-place">'+(fcEvent.typeEvent == this.typeEvent.Navigation?this.bateauService.getLigneById(fcEvent.ligne as number)?.title+'<br/>': '')+'</strong>';
      string += fcEvent.commentaire ? ' <i class="fa fa-comment"></i>&nbsp;' : '';
      string += fcEvent.adminComment && this.userService.isAdmin() ? ' <i class="fa fa-comment text-warning"></i>&nbsp;' : '';
      string += '<small class="faircrew-event-type">'+(fcEvent.typeEvent ==this.typeEvent.Navigation?this.bateauService.getBateauById(fcEvent.bateau as number)?.title: fcEvent.typeEvent)+'</small>';
      string += '</div>';
      string += '<small class="faircrew-event-duration">'+(this.getReadableDurationEvent(fcEvent.durationEvent))+'</small>';
      string += '</div>';
      string += legend != "" ?'<div style="border-top:1px solid #fff"><small>H. Supp : '+legend+'</small></div>' :'';
      string += '<div class="faircrew-event-employes" style="border-top:1px solid #fff">';
      for(let employe of this.userService.populateUsersFromIds(fcEvent.getEmployesIds())){
        string += '<div class="faircrew-event-employe" style="line-height: 1rem">';
        string += (this.eventService.isEventNeedReferent(fcEvent) && employe.id == fcEvent.employeRef ? '<strong>'+
          (fcEvent.employeRefComment ? ' <i class="fa fa-comment"></i>&nbsp;' : '') :'');
        string +=employe.first_name+' '+employe.last_name+'';
        string += (this.eventService.isEventNeedReferent(fcEvent) && employe.id == fcEvent.employeRef ? '&nbsp;<i class="fa fa-star"></i></strong>':'');
        string +='</div>';
      }
      string += '</div>';

      return { html: '<div class="faircrew-event">'+string+'</div>',ref:args.view }
    }
  }

  getBateaux(){
    return this.bateauService.bateauxHttp().then((bateaux)=>{
      this.bateaux = bateaux;
    });
  }

  getReadableDurationEvent(durationEvent: string|DurationEvent){
    if(durationEvent == this.durationEvent.All) return "Journée";
    if(durationEvent == this.durationEvent.Day) return "Journée*";
    if(durationEvent == this.durationEvent.Morning) return "Matin";
    if(durationEvent == this.durationEvent.Afternoon) return "Après-midi";
    if(durationEvent == this.durationEvent.Evening) return "Soir";
  }

  getLignes(){
    return this.bateauService.lignesHttp().then((lignes)=>{
      this.lignes = lignes;
    });
  }

  getEmployes(){
    return this.userService.usersHttp().then((users)=>{
      this.employes = users.filter(user=>user.userRole === "employe" && user.actif == true);
    });
  }

  getEmployesUsedForDay(date){
    this.employesUsed = [];
    const eventArgs = {
      'start':moment(date,'DD/MM/YYYY').valueOf(),
      'end':moment(date,'DD/MM/YYYY').add(1,"day").valueOf(),
      'type': 'ALL',
      'bateau': null,
      'user':null
    };
    this.eventService.eventsHttp(eventArgs)
        .then((events)=>{
          this.employesUsed = [].concat.apply([],
            events
            .filter((event:FcEvent) => !event.durationEvent || event.durationEvent == this.durationEvent.All )
            .map((event:FcEvent) => event.employes.map(employe => employe.employe as number)));
    });
  }

  deleteEvent(event:FcEvent){
    this.eventDelete=event;
    this.closeEvent();
    this.modalDeleteEvent.show();
  }

  onDeleteEvent(){
    this.eventService.deleteEventHttp(this.eventDelete).then(()=>{
      this.modalDeleteEvent.hide();
      this.calendarComponent.getApi().refetchEvents();
      this.toast.success("Evénement correctement supprimé");
    })
  }

  onDateStartEventChange(){
    this.modalEditEvent.config.data.eventEdited.dateEndEvent = this.modalEditEvent.config.data.eventEdited.dateStartEvent;
    this.getEmployesUsedForDay(this.modalEditEvent.config.data.eventEdited.dateStartEvent);
  }

  editEvent(event: FcEvent = null){
    if (event == null){
      event=new FcEvent();
    }
    if(this.userService.isAdmin() || (this.userService.isEmployeRef(event))){
      this.searchEmployesText = "";
      this.eventEdited = event;
      this.modalEditEvent.config.data.eventEdited=JSON.parse(JSON.stringify(event)) as FcEvent;
      this.modalEditEvent.show();
    }
  }

  closeEvent(){
    this.modalEditEvent.hide();
    this.modalEditEvent.config.data.eventEdited.dateStartEvent = null;
  }

  saveEvent(){
    let error = "";
    this.eventEdited=this.modalEditEvent.config.data.eventEdited;
    if(
        this.eventEdited.dateEndEvent &&
        this.eventEdited.dateStartEvent &&
        this.eventEdited.typeEvent &&
        this.eventEdited.employes.length >0
    ) {
      if([TypeEvent.Conges, TypeEvent.Maladie, TypeEvent.Repos].includes(this.eventEdited.typeEvent as TypeEvent)){
        this.eventEdited.employeRef = -1;
      }
      if(!this.eventEdited.heureDebut || this.eventEdited.heureDebut == ''){this.eventEdited.heureDebut = '00:00:00'}
      if(!this.eventEdited.heureFin|| this.eventEdited.heureFin == ''){this.eventEdited.heureFin = '23:59:59'}
      let datetime1 = moment(this.eventEdited.dateStartEvent+' '+ this.eventEdited.heureDebut, "DD/MM/YYYY hh:mm:ss");
      let datetime2 = moment(this.eventEdited.dateEndEvent+' '+ this.eventEdited.heureFin, "DD/MM/YYYY hh:mm:ss");
      if(datetime2>datetime1){
        if (!this.eventEdited.id) {
          this.eventService.addEventHttp(this.eventEdited).then(() => {
            this.calendarComponent.getApi().refetchEvents();
          }).finally(()=>{
            this.closeEvent();
          });
          this.closeEvent();
        } else {

          this.eventEdited = this.modalEditEvent.config.data.eventEdited;
          if(this.userService.isEmployeRef(this.eventEdited)){
            this.eventEdited.employeRefValidation = true;
          }
          if(this.userService.isAdmin() || !this.userService.isEmployeRefValidationLock(this.eventEdited)){
            this.eventService.editEventHttp(this.eventEdited).then(() => {
              this.calendarComponent.getApi().refetchEvents();
              this.toast.success("Evénement correctement mis à jour");
            }).finally(()=>{
              this.modalEditEvent.hide();
              this.eventEdited.dateStartEvent = moment().format('DD-MM-YYYY');
            });
          } else {
            this.toast.error("Vous ne pouvez pas mettre à jour cet événement");
          }
        }
      }else{
        error = "La date de début commence après la date de fin.";
      }
    } else{
      error = "Un des champs n'a pas été correctement saisi.";
    }
    if(error!=''){
      this.toast.error(error);
    }
  }

  employesFiltered(){
    if(this.modalEditEvent){
      const employesSelect = JSON.parse(JSON.stringify(this.modalEditEvent.config.data.eventEdited.employes));
      return this.employes?.filter((e)=>{
        return (
          !this.employesUsed.find((eid)=>eid === e.id) &&
          (e.first_name?.toLowerCase().indexOf(this.searchEmployesText.toLowerCase()) !== -1 ||e.last_name?.toLowerCase().indexOf(this.searchEmployesText.toLowerCase()) !== -1)
            && (employesSelect === undefined || !employesSelect?.map((u)=>{return u.employe})?.find((ee)=>ee.id === e.id)));
      });
    }
    return [];
  }

  removeEmployeFromEvent(employe){
    const employes = this.modalEditEvent.config.data.eventEdited.employes;
    employes.splice(
        employes.findIndex((e)=>{return e.employe.id == employe.employe.id}),
    1);
    if(employes.length>0){
      this.modalEditEvent.config.data.eventEdited.employeRef = employes[0].employe.id;

    }
  }

  addEmployeEvent(employe){
    const employes = this.modalEditEvent.config.data.eventEdited.employes;
    employes.push({employe:employe,time:0});
    if(employes.length==1 && ![TypeEvent.Conges, TypeEvent.Maladie, TypeEvent.Repos].includes(this.modalEditEvent.config.data.eventEdited.typeEvent)){
      this.modalEditEvent.config.data.eventEdited.employeRef = employe.id;
    }
  }

  setTypeEvent(eventType:string ='ALL', eventBateau:number = 0, eventEmploye: number=0){
    this.eventBateau = eventBateau;
    this.eventType = this.eventTypeSelected;
    this.eventUser = eventEmploye;
    this.calendarComponent.getApi().refetchEvents();
  }


  downloadPlanning(){
    window.scrollTo(0, 0);
    setTimeout(()=>{
      html2canvas(this.screen.nativeElement).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        let doc = new jsPDF('l', 'px',[canvas.width+20,canvas.height+20]);
        doc.addImage(imgData, 'PNG', 10, 10,canvas.width,canvas.height);
        const planningName = 'planning-'+moment(this.calendarComponent.getApi().view.currentStart).format('DD-MM-YYYY')+'-'+moment(this.calendarComponent.getApi().view.currentEnd).format('DD-MM-YYYY')+'.pdf';
        doc.save(planningName);
      });
    },500)

  }

  sendPlanningModal(){
    this.modalPlanningEvent.show();
  }

  onSendPlanning(){
    this.isSentPlanning = true;
    this.eventService.sendPlanningHttp(this.dateStartPlanning,this.dateEndPlanning).then(()=>{
        this.toast.success("Planning correctement envoyé !");
    }).finally(()=>{
      setTimeout(()=>{
          this.isSentPlanning = false;
          this.modalPlanningEvent.hide();
      },500);
    });
  }

}
