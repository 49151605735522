import {Entity} from "./Entity";
import {Fonction} from "../enums/Fonction";
import {Contrat} from "../enums/Contrat";

export class User {
    id:number;
    last_name?:string;
    first_name:string;
    username:string;
    password:string;
    email:string;
    numero:string;
    mail:string;
    adresse:string;
    cp:string;
    dateNaissance:Date|string;
    ville:string;
    userRole?:string;
    isReferentGlobal?: boolean;
    canShowCommentaire?: boolean;
    canShowCommentaireRef?: boolean;
    fonction?:Fonction|string;
    contrat?: Contrat|string;
    finContrat?:string;
    conges?:string[];
    portPref?:string;
    actif?:boolean;
    heures?:number;
    heuresSup?:number;
    reassign?:number;
    date: Date;
}
