<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 211.6 50.2" style="enable-background:new 0 0 211.6 50.2;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
  <g>
	<polyline class="st0" points="188.7,30.8 188.7,30.6 188.7,30.1 188.6,29.5 188.4,28.6 187.9,27.8 187.2,27.1 186.2,26.5
		184.8,26.1 183.3,26.2 182.1,26.6 181.1,27.2 180.4,28.1 179.9,28.9 179.6,29.9 179.4,30.7 179.5,31.4 179.7,31.8 179.9,32
		180.2,32.1 180.4,32 180.6,31.6 180.7,31.2 180.9,30.5 181,29.8 181,29.5 181.1,29 181.3,28.7 181.5,28.2 181.8,27.9 182.3,27.5
		183,27.3 183.8,27.2 184.3,27.1 184.9,27.2 185.5,27.3 186.1,27.5 186.6,27.9 187.1,28.3 187.5,29 187.7,29.8 187.9,30.6 188.2,31
		188.5,31.1 	"/>
    <polyline class="st0" points="191,28.7 190.8,28.8 190.6,29 190.5,29.2 190.9,29.4 191.1,29.4 191.5,29.4 192,29.3 192.6,29.2
		193.3,29.1 194,29 194.8,28.8 195.5,28.7 196.3,28.5 197,28.3 197.7,28.2 198.3,28.1 198.8,27.9 199.1,27.9 199.4,27.8 199.5,27.8
			"/>
    <polygon class="st0" points="184.4,19.9 184.5,22.5 184.7,23.6 184.8,23.8 185.2,23.5 185.3,22.5 185.3,21.1 185.3,19.5 185,17.8
		184.9,16.3 184.7,15.3 184.7,14.9 	"/>
    <polyline class="st0" points="188.6,25.1 188.6,25.2 188.6,25.4 188.7,25.6 189.1,25.8 189.2,25.7 189.5,25.6 189.9,25.3 190.4,25
		190.9,24.6 191.4,24.3 192,23.8 192.7,23.3 193.2,22.9 193.8,22.5 194.3,22.1 194.8,21.7 195.2,21.4 195.5,21.2 195.7,21 195.8,21
			"/>
    <polyline class="st0" points="179.7,24.5 179.7,24.7 179.8,25.3 179.6,25.7 179.1,25.6 178.6,25.3 177.8,24.7 176.9,23.9 176,23.1
		175.1,22.3 174.4,21.6 173.9,21.2 173.8,21 	"/>
    <polygon class="st0" points="176.1,28.5 176.2,28.7 176.4,29.1 176.5,29.5 176.2,29.7 175.9,29.7 175.5,29.6 174.8,29.5
		174.1,29.5 173.2,29.4 172.3,29.3 171.3,29.2 170.2,29.1 169.2,29 168.3,28.9 167.3,28.7 166.6,28.7 165.8,28.6 165.3,28.5
		165,28.5 164.9,28.5 	"/>
    <path class="st0" d="M0,46.5c0,0,4.3-3.4,18.1-3.4c0,0,13.8,0.2,23.4,1.8c0,0,9.3,1.3,15.2,1c0,0,11.1,0.3,14.3-1.4
		c0,0-4,2.3-14.3,2.3c0,0-10.9-0.3-15.4-1c0,0-16.4-2.1-23.3-1.7C18,44,10.8,43.3,0,46.5"/>
    <path class="st0" d="M40.1,28.6c0-0.8-0.2-1.4-0.5-1.8c-0.3-0.4-0.9-0.6-1.6-0.6h-0.3c-0.9,0-1.5,0.4-1.7,1.2l-1.1,4.8h1.6
		c1.1,0,2-0.3,2.7-0.9C39.8,30.6,40.1,29.8,40.1,28.6 M39.4,36c0-1.1-0.3-1.9-0.8-2.4c-0.5-0.5-1.3-0.8-2.5-0.8h-1.4l-1.3,5.4
		c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.4,0.1,0.6,0.4,0.8c0.3,0.2,0.8,0.2,1.4,0.2c1.3,0,2.3-0.4,3-1.1S39.4,37.2,39.4,36 M42.4,28.8
		c0,0.8-0.3,1.6-1,2.3c-0.7,0.7-1.6,1.1-2.7,1.3c0.8,0.2,1.5,0.6,2.1,1.3c0.6,0.7,0.9,1.4,0.9,2.2c0,1.4-0.5,2.5-1.6,3.5
		c-1.1,0.9-2.4,1.4-3.9,1.4c-0.5,0-1.2,0-2.1-0.1c-0.8,0-1.5-0.1-1.9-0.1s-1,0-1.7,0.1c-0.7,0-1.3,0.1-1.8,0.1
		c-0.1,0-0.1-0.1-0.1-0.2c0-0.3,0.1-0.4,0.3-0.4c0.6-0.1,1-0.1,1.3-0.3c0.3-0.1,0.6-0.3,0.7-0.4c0.2-0.2,0.3-0.6,0.4-1.2l2.5-10.3
		c0.1-0.4,0.1-0.6,0.1-0.7c0-0.3-0.1-0.6-0.4-0.7c-0.3-0.2-0.8-0.3-1.5-0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3
		c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1,0,1.6,0.1c0.5,0,1,0.1,1.5,0.1c0.4,0,1,0,1.5-0.1c0.6,0,1.1-0.1,1.6-0.1c1.3,0,2.3,0.3,3,0.9
		C42,26.9,42.4,27.8,42.4,28.8"/>
    <path class="st0" d="M50.2,32.9c0-0.4-0.2-0.7-0.5-0.7c-0.6,0-1.3,0.3-2.1,1c-0.8,0.7-1.5,1.5-2.1,2.5c-0.6,1-0.9,1.8-0.9,2.5
		c0,0.4,0.1,0.7,0.2,0.8s0.3,0.3,0.6,0.3c0.4,0,0.9-0.4,1.7-1.3c0.8-0.8,1.5-1.8,2.1-2.9C49.8,34.1,50.2,33.3,50.2,32.9 M52.9,31.1
		l-3.1,7.2c-0.2,0.5-0.3,0.9-0.3,1.1c0,0.2,0.1,0.3,0.2,0.3c0.2,0,0.7-0.4,1.4-1.3c0.3-0.4,0.5-0.6,0.6-0.6c0.1,0,0.2,0,0.2,0.1
		c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.3,0.5-0.8,1.1c-0.5,0.6-1,1.1-1.5,1.4c-0.5,0.3-0.9,0.5-1.2,0.5c-0.7,0-1.1-0.3-1.1-1
		c0-0.6,0.3-1.5,0.9-2.7c-0.5,1-1.2,1.9-2.1,2.6c-0.9,0.7-1.7,1.1-2.3,1.1c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.3-0.3-0.7-0.3-1.2
		c0-1,0.4-2,1.2-3.3c0.8-1.2,1.8-2.3,3-3.2c1.2-0.9,2.2-1.4,2.9-1.4c0.4,0,0.7,0.1,0.9,0.2l0.3-0.6c0.1-0.2,0.5-0.4,1.1-0.4
		C52.6,30.8,52.9,30.9,52.9,31.1L52.9,31.1z"/>
    <path class="st0" d="M60.4,31.8c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2l-2.2-0.1l-2.1,6c-0.1,0.5-0.2,0.8-0.2,1
		c0,0.2,0.1,0.4,0.2,0.4c0.3,0,1-0.5,2-1.4c0.4-0.4,0.6-0.5,0.7-0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.3,0.5-1,1
		c-0.6,0.6-1.2,1-1.8,1.4c-0.6,0.3-1,0.5-1.4,0.5c-0.8,0-1.2-0.4-1.2-1.2c0-0.4,0.1-0.8,0.3-1.3l2.1-6.1l-1.7,0
		c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2h1.8l0.8-2.4c0-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.5-0.1,0.7-0.1
		c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.3,0.2l0,0.1l-0.8,2.4h2.1C60.3,31.4,60.4,31.5,60.4,31.8"/>
    <path class="st0" d="M65.6,33c0-0.6-0.3-0.9-0.8-0.9c-0.5,0-1,0.4-1.5,1.1c-0.5,0.7-0.8,1.6-1,2.7c0.6,0,1.1-0.1,1.6-0.4
		c0.5-0.3,0.9-0.6,1.2-1.1C65.4,33.8,65.6,33.3,65.6,33 M67.1,32.6c0,1-0.5,1.9-1.5,2.7c-1,0.8-2.1,1.2-3.5,1.2
		c0,0.4-0.1,0.8-0.1,1.2c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.5,0.4,0.9,0.4c0.9,0,1.8-0.5,2.5-1.6l0.1,0c0.1,0,0.1,0,0.2,0.1
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.2-0.2,0.5-0.7,1.1c-0.4,0.6-0.9,1-1.4,1.3c-0.5,0.3-1,0.5-1.4,0.5c-0.7,0-1.3-0.3-1.8-0.8
		c-0.5-0.5-0.7-1.2-0.7-1.9c0-1,0.3-2.1,0.8-3.3c0.6-1.1,1.3-2.1,2.1-2.9c0.9-0.8,1.7-1.2,2.4-1.2c0.5,0,0.9,0.1,1.2,0.4
		C67,31.7,67.1,32.1,67.1,32.6"/>
    <path class="st0" d="M75.1,24.9c0,0.1,0,0.3-0.1,0.6l-4.8,12.3c-0.3,0.7-0.4,1.2-0.4,1.3c0,0.2,0.1,0.3,0.2,0.3
		c0.3,0,0.8-0.4,1.5-1.3c0.3-0.4,0.5-0.5,0.5-0.5c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.3,0.5-0.8,1.1
		c-0.5,0.6-1,1.1-1.5,1.4c-0.5,0.3-0.9,0.5-1.2,0.5c-0.8,0-1.2-0.4-1.2-1.2c0-0.4,0.1-0.8,0.3-1.4l4-10.4c0.2-0.4,0.2-0.7,0.2-1
		c0-0.3-0.3-0.5-0.9-0.5c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0.1-0.3,0.2-0.3c0.5-0.1,1-0.3,1.7-0.5c0.7-0.2,1.2-0.5,1.5-0.7
		c0.2-0.1,0.3-0.2,0.4-0.2C75,24.7,75.1,24.8,75.1,24.9"/>
    <path class="st0" d="M80.4,26.8c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.4,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9
		c0-0.3,0.1-0.6,0.4-0.9c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4C80.3,26.2,80.4,26.5,80.4,26.8 M79.1,31.9
		c0,0.5-0.2,1-0.5,1.6l-2.4,4.8c-0.2,0.5-0.4,0.9-0.4,1.1c0,0.2,0.1,0.3,0.2,0.3c0.2,0,0.7-0.4,1.5-1.3c0.4-0.4,0.6-0.6,0.6-0.6
		c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1-0.3,0.5-0.8,1c-0.6,0.6-1.1,1.1-1.6,1.4c-0.5,0.3-0.9,0.5-1.3,0.5
		c-0.7,0-1.1-0.3-1.1-1c0-0.4,0.2-0.9,0.5-1.6l2.4-4.8c0.2-0.5,0.4-0.9,0.4-1.1c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.8,0.5-1.6,1.4
		c-0.3,0.4-0.5,0.6-0.5,0.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.3-0.5,0.8-1s1.1-1.1,1.6-1.4
		c0.5-0.3,0.9-0.5,1.3-0.5C78.8,30.9,79.1,31.2,79.1,31.9"/>
    <path class="st0" d="M85.4,33c0-0.6-0.3-0.9-0.8-0.9c-0.5,0-1,0.4-1.5,1.1c-0.5,0.7-0.8,1.6-1,2.7c0.6,0,1.1-0.1,1.6-0.4
		c0.5-0.3,0.9-0.6,1.2-1.1C85.3,33.8,85.4,33.3,85.4,33 M87,32.6c0,1-0.5,1.9-1.5,2.7c-1,0.8-2.1,1.2-3.5,1.2c0,0.4-0.1,0.8-0.1,1.2
		c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.5,0.4,0.9,0.4c0.9,0,1.8-0.5,2.5-1.6l0.1,0c0.1,0,0.1,0,0.2,0.1C86,38,86,38,86,38.1
		c0,0.2-0.2,0.5-0.7,1.1c-0.4,0.6-0.9,1-1.4,1.3c-0.5,0.3-1,0.5-1.4,0.5c-0.7,0-1.3-0.3-1.8-0.8C80.3,39.7,80,39,80,38.3
		c0-1,0.3-2.1,0.8-3.3c0.6-1.1,1.3-2.1,2.1-2.9c0.9-0.8,1.7-1.2,2.4-1.2c0.5,0,0.9,0.1,1.2,0.4C86.9,31.7,87,32.1,87,32.6"/>
    <path class="st0" d="M96.5,32.2c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.8-0.4c-0.2-0.2-0.4-0.3-0.6-0.3
		c-0.5,0-1.1,0.7-2,2.2c-0.9,1.5-1.8,3.3-2.8,5.6c-0.1,0.2-0.4,0.4-1,0.4c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.2-0.1-0.2-0.2l0-0.1
		l2.3-6.7c0.2-0.6,0.3-0.9,0.3-1.1c0-0.2-0.1-0.3-0.2-0.3c-0.2,0-0.6,0.4-1.2,1.2c-0.3,0.4-0.5,0.6-0.5,0.6c-0.1,0-0.2,0-0.3-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0.2-0.4,0.7-1c0.5-0.6,0.9-1,1.4-1.3c0.4-0.3,0.8-0.5,1.2-0.5c0.8,0,1.1,0.3,1.1,1
		c0,0.4-0.1,0.9-0.3,1.6l-0.4,1.2c1.4-2.5,2.6-3.7,3.7-3.7c0.4,0,0.7,0.1,1,0.3C96.4,31.5,96.5,31.8,96.5,32.2"/>
    <path class="st0" d="M102.4,32.1c0,0.6-0.2,1-0.7,1c-0.2,0-0.6-0.2-1-0.6c-0.4-0.4-0.7-0.6-0.9-0.6c-0.2,0-0.3,0.1-0.4,0.2
		c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.4,0.3,1.2,0.8,2.3c0.6,1.2,0.8,2.1,0.8,2.6c0,0.9-0.3,1.8-1,2.5c-0.6,0.7-1.5,1.1-2.5,1.1
		c-0.5,0-1-0.1-1.4-0.4c-0.4-0.3-0.6-0.6-0.6-0.9c0-0.3,0.1-0.5,0.2-0.7c0.2-0.2,0.4-0.2,0.7-0.2c0.2,0,0.5,0.2,0.8,0.6
		c0.2,0.3,0.5,0.5,0.6,0.6c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.4c0.2-0.3,0.3-0.6,0.3-1.1c0-0.3-0.2-0.9-0.6-1.7
		c-0.6-1.2-0.9-2.2-0.9-2.9c0-0.8,0.3-1.5,0.9-2.1c0.6-0.7,1.3-1,2.1-1c0.5,0,1,0.1,1.3,0.3C102.2,31.5,102.4,31.8,102.4,32.1"/>
    <path class="st0" d="M107.4,38.1c0,0,9.4-21.5,41-26.4c0,0-39.1-8.8-126,12.9c0,0,0.1,0.8,4.6-0.1c4.5-0.8,74.5-15.3,109.9-11.9
		C136.9,12.6,111.8,19.1,107.4,38.1"/>
    <path class="st0" d="M111.1,12.2c0,0-0.1-12-26.6-9c0,0-34.8,6.2-47.7,11.9c0,0,0.9,0.4,3.7-0.3c0,0,36.4-9.5,46.9-9.8
		c0,0,15.3-1.8,17.3,7.4L111.1,12.2z"/>
    <path class="st0" d="M61.5,0.3c0,0-0.8-0.5-2.9-0.3c0,0-9.4,0.6-20.7,5.1c0,0-4.5,1.4,4.1,8.2l4.3-1.3l-5.8-5c0,0-2-1.6,2.5-2.7
		C42.9,4.4,50.9,2,61.5,0.3"/>
    <path class="st0" d="M88.8,46.4c0-0.3-0.1-0.5-0.4-0.5c-0.3,0-0.5,0.2-0.9,0.5c-0.3,0.3-0.6,0.7-0.8,1.2c-0.2,0.5-0.3,0.9-0.3,1.3
		c0,0.3,0.1,0.4,0.3,0.4c0.2,0,0.5-0.2,0.8-0.5c0.3-0.3,0.6-0.8,0.9-1.3C88.7,47,88.8,46.6,88.8,46.4 M90.8,43.1c0,0,0,0.1,0,0.2
		l-1.9,5.3c-0.1,0.3-0.2,0.5-0.2,0.6c0,0.1,0,0.2,0.1,0.2c0.1,0,0.3-0.2,0.6-0.6c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.3,0.5c-0.2,0.3-0.4,0.5-0.6,0.6C88.6,50,88.5,50,88.3,50c-0.3,0-0.5-0.2-0.5-0.5
		c0-0.2,0.1-0.5,0.3-0.9c-0.2,0.4-0.5,0.8-0.9,1c-0.3,0.3-0.6,0.4-0.9,0.4c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4-0.2-0.7
		c0-0.4,0.2-0.9,0.5-1.5c0.3-0.5,0.7-1,1.2-1.4c0.5-0.4,0.9-0.6,1.2-0.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2l0.5-1.5
		c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1-0.1-0.2-0.4-0.2c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.2,0,0.4-0.1,0.7-0.2
		c0.3-0.1,0.5-0.2,0.6-0.3c0.1,0,0.1-0.1,0.2-0.1C90.8,43,90.8,43,90.8,43.1"/>
    <path class="st0" d="M92.5,46.5c0-0.3-0.1-0.4-0.3-0.4c-0.2,0-0.4,0.2-0.6,0.5c-0.2,0.3-0.3,0.7-0.4,1.1c0.3,0,0.5-0.1,0.7-0.2
		c0.2-0.1,0.4-0.3,0.5-0.5C92.4,46.9,92.5,46.7,92.5,46.5 M93.1,46.4c0,0.4-0.2,0.8-0.6,1.2c-0.4,0.3-0.9,0.5-1.5,0.5
		c0,0.2,0,0.4,0,0.5c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.2,0.4,0.2c0.4,0,0.8-0.2,1.1-0.7l0,0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1
		c0,0.1-0.1,0.2-0.3,0.5c-0.2,0.2-0.4,0.4-0.6,0.6S91.4,50,91.2,50c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.8
		c0-0.5,0.1-0.9,0.4-1.4c0.2-0.5,0.5-0.9,0.9-1.2c0.4-0.3,0.7-0.5,1-0.5c0.2,0,0.4,0.1,0.5,0.2C93.1,46,93.1,46.2,93.1,46.4"/>
    <path class="st0" d="M98.5,43.1c0,0.1,0,0.1-0.1,0.2l-2.1,5.3c-0.1,0.3-0.2,0.5-0.2,0.5c0,0.1,0,0.1,0.1,0.1c0.1,0,0.3-0.2,0.6-0.5
		c0.1-0.2,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.2-0.3,0.5c-0.2,0.3-0.4,0.5-0.6,0.6C96.1,50,96,50,95.8,50
		c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0-0.4,0.1-0.6l1.7-4.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1-0.1-0.2-0.4-0.2c-0.1,0-0.1,0-0.1-0.1
		c0-0.1,0-0.1,0.1-0.1c0.2,0,0.4-0.1,0.7-0.2c0.3-0.1,0.5-0.2,0.6-0.3c0.1,0,0.1-0.1,0.2-0.1C98.5,43,98.5,43,98.5,43.1"/>
    <path class="st0" d="M101,46.5c0-0.2-0.1-0.3-0.2-0.3c-0.3,0-0.6,0.1-0.9,0.4c-0.3,0.3-0.6,0.7-0.9,1.1c-0.3,0.4-0.4,0.8-0.4,1.1
		c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.1,0.1,0.3,0.1c0.2,0,0.4-0.2,0.7-0.5c0.3-0.4,0.6-0.8,0.9-1.2C100.8,47.1,101,46.7,101,46.5
		 M102.1,45.8l-1.3,3.1c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.1,0.1,0.1c0.1,0,0.3-0.2,0.6-0.6c0.1-0.2,0.2-0.3,0.2-0.3
		c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.2-0.3,0.5c-0.2,0.3-0.4,0.5-0.6,0.6c-0.2,0.1-0.4,0.2-0.5,0.2
		c-0.3,0-0.5-0.1-0.5-0.4c0-0.3,0.1-0.7,0.4-1.1c-0.2,0.4-0.5,0.8-0.9,1.1c-0.4,0.3-0.7,0.5-1,0.5c-0.2,0-0.3-0.1-0.4-0.2
		c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.4,0.2-0.9,0.5-1.4c0.3-0.5,0.8-1,1.3-1.4c0.5-0.4,0.9-0.6,1.3-0.6c0.2,0,0.3,0,0.4,0.1l0.1-0.3
		c0-0.1,0.2-0.2,0.5-0.2C102,45.6,102.1,45.7,102.1,45.8L102.1,45.8z"/>
    <path class="st0" d="M117.9,37.5c-0.1,0.5-0.2,1.5-0.3,3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.1-0.2-0.2c0-1.6-0.7-2.5-2.2-2.5
		c-1.1,0-2.1,0.4-3.1,1.1c-1,0.7-1.7,1.8-2.3,3c-0.5,1.3-0.8,2.7-0.8,4.2c0,1,0.3,1.8,1,2.4c0.6,0.6,1.5,0.9,2.5,0.9
		c1,0,1.8-0.2,2.4-0.7c0.6-0.5,1.2-1.3,1.7-2.4c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2c-0.2,0.7-0.5,1.8-0.8,3.2
		c-1.4,0.4-2.7,0.6-4,0.6c-1.5,0-2.7-0.4-3.7-1.3c-0.9-0.9-1.4-2-1.4-3.5c0-1.5,0.4-2.8,1.2-4.1c0.8-1.3,1.9-2.2,3.3-3
		c1.4-0.7,2.9-1.1,4.6-1.1C116.7,37.3,117.3,37.3,117.9,37.5"/>
    <path class="st0" d="M124.4,40.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.1-0.1l-1.2-1.7l-2,1.7l-0.1,0
		c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2l2.4-2.6c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1
		L124.4,40.6L124.4,40.6z M123.1,43.6c0-0.4,0-0.6-0.1-0.8c-0.1-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.9,0.3-1.4,1c-0.5,0.7-0.9,1.4-1.2,2.4
		c-0.3,0.9-0.5,1.8-0.5,2.7c0,0.4,0.1,0.7,0.2,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.4,0,0.8-0.3,1.3-1c0.5-0.7,0.9-1.4,1.2-2.4
		C122.9,45.3,123.1,44.4,123.1,43.6 M124.5,44.7c0,0.8-0.2,1.7-0.7,2.6c-0.4,0.9-1,1.6-1.7,2.1c-0.7,0.5-1.4,0.8-2.1,0.8
		c-0.6,0-1.1-0.3-1.5-0.8c-0.4-0.5-0.6-1.2-0.6-1.9c0-0.8,0.2-1.7,0.7-2.6c0.4-0.9,1-1.6,1.7-2.1c0.7-0.5,1.4-0.8,2.1-0.8
		c0.6,0,1.1,0.3,1.5,0.8C124.3,43.3,124.5,43.9,124.5,44.7"/>
    <path class="st0" d="M131.2,42.7c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.2l-1.8-0.1l-1.7,4.9c-0.1,0.4-0.2,0.6-0.2,0.8
		c0,0.2,0.1,0.3,0.2,0.3c0.3,0,0.8-0.4,1.7-1.2c0.3-0.3,0.5-0.4,0.5-0.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
		c0,0.1-0.3,0.4-0.8,0.9c-0.5,0.5-1,0.9-1.5,1.1c-0.5,0.3-0.8,0.4-1.1,0.4c-0.7,0-1-0.3-1-1c0-0.3,0.1-0.7,0.2-1.1l1.7-5l-1.4,0
		c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.2-0.2h1.4l0.7-1.9c0-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.4-0.1,0.6-0.1
		c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.1,0.2,0.2l0,0l-0.7,1.9h1.7C131.1,42.4,131.2,42.5,131.2,42.7"/>
    <path class="st0" d="M135.4,43.6c0-0.5-0.2-0.8-0.7-0.8c-0.4,0-0.8,0.3-1.2,0.9c-0.4,0.6-0.6,1.3-0.8,2.2c0.5,0,1-0.1,1.3-0.3
		c0.4-0.2,0.7-0.5,1-0.9C135.3,44.3,135.4,43.9,135.4,43.6 M136.7,43.3c0,0.8-0.4,1.5-1.2,2.2c-0.8,0.7-1.8,1-2.8,1
		c0,0.4-0.1,0.7-0.1,1c0,0.5,0.1,0.9,0.2,1.1c0.1,0.2,0.4,0.3,0.7,0.3c0.8,0,1.4-0.4,2-1.3l0.1,0c0,0,0.1,0,0.2,0.1
		c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.2,0.4-0.5,0.9c-0.4,0.5-0.7,0.8-1.1,1.1c-0.4,0.3-0.8,0.4-1.2,0.4c-0.6,0-1.1-0.2-1.4-0.7
		c-0.4-0.4-0.6-1-0.6-1.6c0-0.9,0.2-1.7,0.7-2.7c0.5-0.9,1-1.7,1.7-2.3c0.7-0.6,1.3-0.9,1.9-0.9c0.4,0,0.8,0.1,1,0.4
		C136.5,42.6,136.7,42.9,136.7,43.3"/>
    <path class="st0" d="M144.1,46.4c0-0.3-0.1-0.5-0.4-0.5c-0.3,0-0.5,0.2-0.9,0.5c-0.3,0.3-0.6,0.7-0.8,1.2c-0.2,0.5-0.3,0.9-0.3,1.3
		c0,0.3,0.1,0.4,0.3,0.4c0.2,0,0.5-0.2,0.8-0.5c0.3-0.3,0.6-0.8,0.9-1.3C143.9,47,144.1,46.6,144.1,46.4 M146.1,43.1
		c0,0,0,0.1,0,0.2l-1.9,5.3c-0.1,0.3-0.2,0.5-0.2,0.6c0,0.1,0,0.2,0.1,0.2c0.1,0,0.3-0.2,0.6-0.6c0.1-0.1,0.2-0.2,0.2-0.2
		c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.3,0.5c-0.2,0.3-0.4,0.5-0.6,0.6c-0.2,0.1-0.4,0.2-0.5,0.2
		c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0.1-0.5,0.3-0.9c-0.2,0.4-0.5,0.8-0.9,1c-0.3,0.3-0.6,0.4-0.9,0.4c-0.2,0-0.4-0.1-0.5-0.3
		c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.4,0.2-0.9,0.5-1.5c0.3-0.5,0.7-1,1.2-1.4c0.5-0.4,0.9-0.6,1.2-0.6c0.1,0,0.2,0,0.3,0.1
		c0.1,0.1,0.2,0.1,0.3,0.2l0.5-1.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1-0.1-0.2-0.4-0.2c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1
		c0.2,0,0.4-0.1,0.7-0.2c0.3-0.1,0.5-0.2,0.6-0.3c0.1,0,0.1-0.1,0.2-0.1C146,43,146.1,43,146.1,43.1"/>
    <path class="st0" d="M147.5,43.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2l-0.2,2.3
		c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.8-2.1c0,0,0,0,0-0.1C147.7,43.5,147.6,43.4,147.5,43.4"/>
    <path class="st0" d="M156.7,40.1l-3.4,4.3h3L156.7,40.1z M159.6,49.6c0,0.2-0.1,0.3-0.2,0.3c-0.4,0-0.9,0-1.4-0.1
		c-0.5,0-0.9-0.1-1.2-0.1c-0.4,0-0.8,0-1.4,0.1c-0.6,0-1.1,0.1-1.5,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0.1-0.3,0.2-0.3
		c0.7,0,1.2-0.2,1.4-0.4c0.3-0.2,0.4-0.6,0.5-1.2l0.3-2.9h-3.4l-2.2,2.8c-0.4,0.5-0.6,0.8-0.6,1.1c0,0.4,0.3,0.6,1.1,0.6
		c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.4,0-0.7,0-1.1-0.1c-0.4,0-0.8-0.1-1.1-0.1
		c-0.3,0-0.6,0-1.1,0.1c-0.5,0-0.9,0.1-1.2,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0.1-0.3,0.2-0.3c0.6-0.1,1.2-0.2,1.7-0.5
		c0.5-0.3,1-0.7,1.5-1.3l8.5-10.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.2,0.2l-0.9,10.3c0,0.1,0,0.2,0,0.3
		c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.7,0.3,1.4,0.4C159.5,49.4,159.6,49.5,159.6,49.6"/>
    <path class="st0" d="M167.8,42.7c0,0.1-0.2,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.5,0.4l-4.7,4.7c0.1-0.1,0.4-0.2,0.7-0.2
		c0.3,0,0.8,0.2,1.5,0.7c0.7,0.4,1.1,0.6,1.3,0.6c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.4c-0.2-0.2-0.2-0.4-0.2-0.6
		c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.2,0.8c0,0.4-0.2,0.8-0.6,1.2
		c-0.4,0.4-0.9,0.5-1.5,0.5c-0.3,0-0.8-0.1-1.6-0.4c-0.7-0.2-1.2-0.4-1.4-0.4c-0.3,0-0.6,0.2-0.8,0.5c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.4l4.7-4.8
		c-0.1,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.8-0.2-1.4-0.5c-0.6-0.3-1-0.5-1.3-0.5c-0.3,0-0.4,0.1-0.4,0.3c0,0.1,0.1,0.3,0.4,0.4
		c0.3,0.2,0.5,0.4,0.5,0.6c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.2-0.5-0.2-0.8
		c0-0.5,0.2-1,0.6-1.4s0.9-0.6,1.4-0.6c0.3,0,0.8,0.2,1.4,0.5c0.6,0.3,1,0.4,1.3,0.4c0.3,0,0.5-0.2,0.8-0.6c0.1-0.2,0.3-0.4,0.4-0.4
		c0.2,0,0.3,0.1,0.5,0.2C167.8,42.3,167.8,42.5,167.8,42.7"/>
    <path class="st0" d="M176.2,42.5l-1.9,5.5c-0.2,0.5-0.2,0.8-0.2,0.9c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.5-0.3,1-1
		c0.2-0.3,0.4-0.5,0.4-0.5c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.2,0.4-0.6,0.8c-0.4,0.5-0.8,0.8-1.1,1.1
		c-0.4,0.3-0.7,0.4-0.9,0.4c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.3,0.1-0.7,0.3-1.3l0.6-1.7
		c-0.7,1.2-1.4,2.1-2.1,2.8c-0.7,0.7-1.3,1-1.8,1c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.9,0.4-1.5l1.4-3.4
		c0.2-0.5,0.3-0.8,0.3-0.9c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.6,0.3-1.1,1c-0.2,0.3-0.4,0.4-0.4,0.4c-0.1,0-0.1,0-0.2-0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.2-0.4,0.6-0.8c0.4-0.4,0.8-0.8,1.2-1c0.4-0.3,0.7-0.4,1-0.4c0.3,0,0.5,0.1,0.6,0.2
		c0.2,0.1,0.2,0.3,0.2,0.5c0,0.3-0.1,0.8-0.4,1.4l-1.5,3.4c-0.2,0.4-0.3,0.7-0.3,1c0,0.3,0.1,0.5,0.4,0.5c0.3,0,0.8-0.4,1.5-1.2
		c0.6-0.8,1.3-1.9,2.1-3.3l0.7-1.9c0-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.3,0,0.5,0.1
		C176.1,42.3,176.2,42.4,176.2,42.5"/>
    <path class="st0" d="M184,43c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.3-0.5-0.3
		c-0.4,0-0.9,0.6-1.7,1.8c-0.7,1.2-1.5,2.7-2.3,4.5c-0.1,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.2-0.2l0,0
		l1.9-5.5c0.2-0.5,0.2-0.8,0.2-0.9c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.5,0.3-1,1c-0.2,0.3-0.4,0.5-0.4,0.5c-0.1,0-0.2,0-0.2-0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.2-0.4,0.6-0.8c0.4-0.5,0.8-0.8,1.1-1.1c0.4-0.3,0.7-0.4,0.9-0.4c0.6,0,0.9,0.3,0.9,0.8
		c0,0.3-0.1,0.7-0.3,1.3l-0.3,1c1.1-2,2.1-3,3-3c0.3,0,0.6,0.1,0.8,0.3C183.9,42.4,184,42.7,184,43"/>
    <path class="st0" d="M211.6,34.4c0,0-6-2.5-25.1-2.5c0,0-19.3,0.2-32.6,1.3c0,0-13,1-21.1,0.7c0,0-15.4,0.2-19.9-1
		c0,0,5.6,1.8,19.8,1.7c0,0,15.1-0.2,21.4-0.8c0,0,22.8-1.6,32.4-1.3C186.6,32.6,196.6,32.1,211.6,34.4"/>
</g>
</svg>
