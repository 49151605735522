<div class="row" *ngIf="userService.isAdmin()">
  <div class="col-12">
    <div class="md-form">
      <button mdbBtn [color]="selectedBateau == null? 'pink':'primary'" mdbWavesEffect (click)="selectedBateau = null;refreshResources()" >
        Tous
      </button>
      <button mdbBtn [color]="selectedBateau == bateau? 'pink':'primary'" mdbWavesEffect (click)="selectedBateau = bateau;refreshResources()" *ngFor="let bateau of bateaux">
        {{bateau.title}}
      </button>
    </div>

  </div>
</div>
<div class="card p-2">
  <div class="row">
    <div class="col-12 mb-4">
      <div #calendarScreen>
        <full-calendar #calendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
      </div>
    </div>

  </div>
</div>
