<div class="row mt-3">
    <div class="col-md-12">
      <div class="md-form">
        <button mdbBtn color="pink" mdbWavesEffect (click)="editEmploye()">
        <mdb-icon fas icon="plus" class="mr-2"></mdb-icon> Ajouter un collaborateur</button>
      </div>
        <mdb-card-body cascade="true" class="mt-1" *ngIf="employes && employes.length">
            <!--Basic example-->
            <!-- Nav Tabs -->
            <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3 blue'" [contentClass]="'card'">
                <!--Panel 1-->
                <mdb-tab heading="Liste des {{employes.length}} collaborateurs (dont {{getInactifUsers().length }} inactifs)">
                    <div class="row">
                        <div class="col-12">
                            <br>
                            <mdb-card cascade="true" narrower="true">
                                <div class="px-4">
                                    <div class="table-wrapper">
                                        <!--Table-->
                                        <table mdbTable small="true" striped="true">
                                            <!--Table head-->
                                            <thead>
                                            <tr >
                                                <th>
                                                    <a (click)="sortBy('id')"
                                                    >Id
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                              <th>
                                                <a (click)="sortBy('actif')">
                                                  S. <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                </a>
                                              </th>
                                                <th>
                                                    <a (click)="sortBy('last_name')"
                                                    >Nom
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                              <th>
                                                <a (click)="sortBy('first_name')"
                                                >Prénom
                                                  <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                </a>
                                              </th>
                                                <th>
                                                    <a>Numero
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a>Email
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                              <th  class="th-lg">&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <!--Table head-->

                                            <!--Table body-->
                                            <tbody>
                                            <tr mdbTableCol *ngFor="let employe of employes">
                                                <td>{{ employe.id }}</td>
                                                <td>
                                                  <mdb-icon [ngClass]="{'text-success':employe.actif,'text-danger':!employe.actif}" fas icon="circle"></mdb-icon>
                                                </td>
                                                <td>{{ employe.last_name }}</td>
                                                <td>{{ employe.first_name }}</td>
                                                <td>{{ employe.numero }}</td>
                                                <td>{{ employe.mail }}</td>
                                                <td><div>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="pink"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            mdbTooltip="Afficher" placement="top"
                                                            (click)="showEmploye(employe)"
                                                            mdbWavesEffect
                                                    >
                                                        <mdb-icon fas icon="eye" class="mt-0"></mdb-icon>
                                                    </button>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="blue"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            *ngIf="employe.actif"
                                                            mdbTooltip="Editer" placement="top"
                                                            (click)="editEmploye(employe)"
                                                            mdbWavesEffect
                                                    >
                                                        <mdb-icon fas icon="pencil-alt" class="mt-0"></mdb-icon>
                                                    </button>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="grey"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"

                                                            (click)="toggleActif(employe)"
                                                            mdbWavesEffect
                                                    >

                                                        <mdb-icon *ngIf="employe.actif" mdbTooltip="Désactiver" placement="top" fas icon="user-lock" class="mt-0"></mdb-icon>
                                                        <mdb-icon *ngIf="!employe.actif" mdbTooltip="Activer" placement="top" fas icon="user-check" class="mt-0"></mdb-icon>
                                                    </button>
                                                  <button
                                                    type="button"
                                                    mdbBtn
                                                    outline="true"
                                                    color="red"
                                                    rounded="true"
                                                    size="sm"
                                                    class="px-2"
                                                    mdbTooltip="Supprimer" placement="top"
                                                    *ngIf="!employe.actif"
                                                    (click)="deleteEmploye(employe)"
                                                  >
                                                    <mdb-icon fas icon="times" class="mt-0"></mdb-icon>
                                                  </button>
                                                </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <!--Table body-->
                                        </table>
                                        <!--Table-->
                                    </div>

                                    <hr class="my-0" />

                                </div>
                            </mdb-card>
                        </div>
                    </div>
                </mdb-tab>
            </mdb-tabset>
        </mdb-card-body>
    </div>
    <div class="col-12">

    </div>
</div>
<div mdbModal #modalShowEmploye="mdb-modal" class="modal fade "
     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" *ngIf="employeShow !== undefined">Infos de l'employé {{employeShow.first_name}} {{employeShow.last_name}}</h4>
                <button type="button" class="close" data-dismiss="modal"
                        aria-label="Close" (click)="modalShowEmploye.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="employeShow !== undefined">
              <div class="row">
                <div class="col-6">
                  <div></div>
                  <p><b>Prénom</b> :
                    {{ employeShow.first_name }} </p>
                  <p><b>Nom</b> :
                    {{ employeShow.last_name }} </p>
                  <p><b>Téléphone</b>  :
                    {{ employeShow.numero }} </p>
                  <p><b>Email </b>:
                    {{ employeShow.mail }} </p>
                  <p><b>Date de naissance</b> :
                    {{ employeShow.dateNaissance| date: 'dd/MM/yyyy' }} </p>
                  <p><b>Adresse</b> :
                    {{ employeShow.adresse }} </p>
                  <p><b>Code postal</b>:
                    {{ employeShow.cp}}</p>
                  <p><b>Ville</b>:
                    {{ employeShow.ville }}</p>
                  <p><b>Fonction</b>:
                    {{ employeShow.fonction }}</p>
                  <p><b>Port préféré</b>:
                    {{ employeShow.portPref }}</p>

                </div>
                <div class="col-6">
                  <p><b>Type de contrat</b>:
                    {{ employeShow.contrat }}</p>
                  <p><b>Date fin du contrat</b>:
                    {{ employeShow.finContrat}}</p>
                  <p><b>Nombre d'heures</b>:
                    {{ employeShow.heures}}</p>
                  <p><b>Nombre d'heures sup</b>:
                    {{ employeShow.heuresSup}}</p>
                  <p>Jours de repos préférés:</p>
                  <ul>
                    <li *ngFor="let jour of employeShow.conges">{{jour}}</li>
                  </ul>

                </div>
              </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalEditEmploye="mdb-modal" class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document" *ngIf="employeEdited !== undefined && modalEditEmploye.config.data.employeEdit !== undefined">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" *ngIf="employeEdited.id">Modifier les infos du collaborateur</h4>
                <h4 class="modal-title w-100" *ngIf="!employeEdited.id">Ajouter une fiche collaborateur</h4>
                <button type="button" class="close" data-dismiss="modal"
                        aria-label="Close" (click)="modalEditEmploye.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-8">
                        <div class="row">
                            <div class="col-6">
                                <p>Prénom :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.first_name" class="form-control pt-0" mdbInput></p>
                                <p>Email :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.mail" class="form-control pt-0" mdbInput></p>
                            </div>
                            <div class="col-6">
                                <p>Nom :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.last_name" class="form-control pt-0" mdbInput></p>
                                <p>Téléphone :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.numero" class="form-control pt-0" mdbInput></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p>Adresse:<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.adresse" class="form-control pt-0" mdbInput></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <p>Code Postal :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.cp" class="form-control pt-0" mdbInput></p>
                            </div>
                            <div class="col-6">
                                <p>Ville :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.ville" class="form-control pt-0" mdbInput></p>
                            </div>
                            <div class="col-6">
                                <p>Date de naissance :<br/>
                                    <mdb-date-picker #datePicker [options]="datePickerBirthOptions"  name="dateNaissance" [(ngModel)]="modalEditEmploye.config.data.employeEdit.dateNaissance" [placeholder]="'Date de naissance'" required></mdb-date-picker>
                                </p>
                            </div>
                            <div class="col-6">

                                <b>Fonction </b>:<br/>
                                <mdb-select
                                        [options]="showOnlyFonctionSelect"
                                        [(ngModel)]="modalEditEmploye.config.data.employeEdit.fonction"
                                        placeholder="Sélectionner une fonction"
                                >
                                </mdb-select>
                            </div>
                            <div class="col-6">
                                <b>Type de contrat </b>:<br/>
                                <mdb-select
                                        [options]="showOnlyContratSelect"
                                        [(ngModel)]="modalEditEmploye.config.data.employeEdit.contrat"
                                        placeholder="Sélectionner un type"
                                >
                                </mdb-select>
                            </div>
                            <div class="col-6" *ngIf="modalEditEmploye.config.data.employeEdit.contrat !== 'CDI'">
                                <p>Date fin de contrat :<br/>
                                    <mdb-date-picker #datePicker name="dateFinContrat" locale="fr" [(ngModel)]="modalEditEmploye.config.data.employeEdit.finContrat" [placeholder]="'Date de fin de contrat'"></mdb-date-picker>


                            </div>
                            <div class="col-6">
                                <p>Nombre d'heures:<br/>
                                    <input type="number" [(ngModel)]="modalEditEmploye.config.data.employeEdit.heures" class="form-control pt-0" mdbInput></p>
                            </div>
                            <div class="col-6">
                                <p>Nombre d'heures sup:<br/>
                                    <input type="number" [(ngModel)]="modalEditEmploye.config.data.employeEdit.heuresSup" class="form-control pt-0" mdbInput></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <!--<div>
                            Jours de repos préférés:
                        </div>
                        <div class="btn-group flex-wrap mb-2">
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.lu" mdbCheckbox mdbWavesEffect>
                                Lu
                            </label>
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.ma" mdbCheckbox mdbWavesEffect>
                                Ma
                            </label>
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.me" mdbCheckbox mdbWavesEffect>
                                Me
                            </label>
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.je" mdbCheckbox mdbWavesEffect>
                                Je
                            </label>
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.ve" mdbCheckbox mdbWavesEffect>
                                Ve
                            </label>
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.sa" mdbCheckbox mdbWavesEffect>
                                Sa
                            </label>
                            <label mdbBtn color="primary" size="sm" class="waves-light p-1 m-1" [(ngModel)]="checkCongesEmploye.di" mdbCheckbox mdbWavesEffect>
                                Di
                            </label>
                        </div>-->
                        <div>
                            <div>
                                <p>Mot de passe :<br/>
                                    <input type="text" [(ngModel)]="modalEditEmploye.config.data.employeEdit.password" class="form-control pt-0" mdbInput></p>
                            </div>
                            <b>Port préféré </b>:<br/>
                            <select class="browser-default custom-select" [(ngModel)]="modalEditEmploye.config.data.employeEdit.portPref">
                                <option disabled>Sélectionner un port</option>
                                <option [selected]="modalEditEmploye.config.data.employeEdit.portPref==port.id" *ngFor="let port of ports" [value]="port.id">{{ port.title }}</option>
                            </select>
                            <br/>
                            <div class="form-check">
                              <input class="form-check-input" [(ngModel)]="modalEditEmploye.config.data.employeEdit.isReferentGlobal" type="checkbox" [value]="true" id="isReferentGlobal" [checked]="modalEditEmploye.config.data.employeEdit.isReferentGlobal"/>
                              <label class="form-check-label" for="isReferentGlobal">Accés planning complet ?</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" [(ngModel)]="modalEditEmploye.config.data.employeEdit.canShowCommentaire" type="checkbox" [value]="true" id="canShowCommentaire" [checked]="modalEditEmploye.config.data.employeEdit.canShowCommentaire"/>
                              <label class="form-check-label" for="canShowCommentaire">Peut voir les commentaires ?</label>
                            </div>
                          <div class="form-check">
                            <input class="form-check-input" [(ngModel)]="modalEditEmploye.config.data.employeEdit.canShowCommentaireRef" type="checkbox" [value]="true" id="canShowCommentaireRef" [checked]="modalEditEmploye.config.data.employeEdit.canShowCommentaireRef"/>
                            <label class="form-check-label" for="canShowCommentaireRef">Peut voir les commentaires des référents ?</label>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" size="sm"
                        class=" waves-light" data-dismiss="modal"
                        (click)="modalEditEmploye.hide()" mdbWavesEffect>Fermer
                </button>
                <button type="button" mdbBtn color="primary" size="sm"
                        class="waves-light" (click)="saveEditEmploye()"  mdbWavesEffect>Enregistrer

                </button>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalDeleteEmploye="mdb-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-danger modal-dialog-scrollable" role="document" *ngIf="employeDelete !== undefined">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Etes vous certain de vouloir supprimer cette fiche ?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modalDeleteEmploye.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Etes vous certain de vouloir supprimer la fiche de {{ employeDelete.first_name }} {{ employeDelete.last_name }} ?</p>
                    <p>Celle-ci sera supprimée DEFINITIVEMENT.</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="default" outline="true" (click)="modalDeleteEmploye.hide()" mdbWavesEffect>Annuler
                </a>
                <a (click)="onDeleteEmploye()" type="button" mdbBtn color="secondary"  data-dismiss="modal"
                   mdbWavesEffect>Oui, je suis certain</a>
            </div>
        </div>
    </div>
</div>

