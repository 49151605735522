<div class="row" *ngIf="userService.isAdmin()" class="planning-toolbar">
    <div class="col-12">
        <div class="md-form">
          <button mdbBtn color="pink" mdbWavesEffect (click)="editEvent()">
            <mdb-icon fas icon="plus" class="mr-2"></mdb-icon> Ajouter une entrée au planning
          </button>
          <button mdbBtn color="deep-purple" class="waves-light m-1" (click)="downloadPlanning()" mdbWavesEffect>
            <mdb-icon fas icon="download" class="mr-2"></mdb-icon> Exporter le planning de la semaine
          </button>
          <button mdbBtn color="blue" class="waves-light m-1" (click)="sendPlanningModal()" mdbWavesEffect>
            <mdb-icon fas icon="paper-plane" class="mr-2"></mdb-icon> Notifier les collaborateurs
          </button>
        </div>
    </div>
</div>
<div class="card p-2 planning-content">
    <div class="row">
        <div class="col-12 mb-4">
            <div class="form-inline mb-3 planning-filters" *ngIf="userService.isAdmin()">
                <label mdbBtn color="primary"  class="waves-light m-1" (click)="setTypeEvent(typeEvent.All)" mdbRadio="{{typeEvent.All}}" [(ngModel)]="eventTypeSelected" mdbWavesEffect>
                    Tous
                </label>
                <label class="ml-2">
                  Par type :&nbsp;
                  <select class="form-control form-control-sm" (change)="setTypeEvent($event.target.value)" [(ngModel)]="eventTypeSelected">
                    <option [selected]="typeEvent.All == eventTypeSelected" [ngValue]="typeEvent.All">Tous</option>
                    <option [ngValue]="typeEvent.Navigation">Navigation</option>
                    <option [ngValue]="typeEvent.Repos">Repos</option>
                    <option [ngValue]="typeEvent.CaisseToulon">Caisse Toulon</option>
                    <option [ngValue]="typeEvent.CaisseLaRade">Caisse Rade</option>
                    <option [ngValue]="typeEvent.CaisseLaLonde">Caisse La Londe</option>
                    <option [ngValue]="typeEvent.Chantier">Chantier</option>
                    <option [ngValue]="typeEvent.EntretienBateau">Entretien Bâteau</option>
                    <option [ngValue]="typeEvent.Formation">Formation</option>
                    <option [ngValue]="typeEvent.Ecole">Ecole</option>
                    <option [ngValue]="typeEvent.Reunion">Réunion</option>
                    <option [ngValue]="typeEvent.Direction">Direction</option>
                    <option [ngValue]="typeEvent.Presta">Prestation</option>
                    <option [ngValue]="typeEvent.Escale">Escale</option>
                    <option [ngValue]="typeEvent.Bureau">Bureau</option>
                    <option [ngValue]="typeEvent.Maladie">Maladie</option>
                    <option [ngValue]="typeEvent.Conges">Congés</option>
                    <option [ngValue]="typeEvent.Recuperation">Récupération</option>
                    <option [ngValue]="typeEvent.Affretement">Affrètement</option>
                    <option [ngValue]="typeEvent.RS">Réseaux sociaux</option>
                    <option [ngValue]="typeEvent.ReposAnticipe">Repos anticipé</option>
                    <option [ngValue]="typeEvent.Prospectus">Prospectus</option>
                    <option [ngValue]="typeEvent.FinContrat">Fin de contrat</option>
                    <option [ngValue]="typeEvent.Quai">Quai</option>
                    <option [ngValue]="typeEvent.Autre">Autre</option>
                  </select>
                </label>
                <label class="ml-2" *ngIf="typeEvent.Navigation == eventTypeSelected">
                Par bateau :&nbsp;
                <select class="form-control form-control-sm" (change)="setTypeEvent(typeEvent.Navigation,$event.target.value,0)">
                    <option [selected]="eventBateau==0" value="0">Tous</option>
                    <option value="{{bateau.id}}" *ngFor="let bateau of bateaux">{{bateau.title}}</option>
                </select>
                </label>
                <label class="ml-2">
                Par collaborateur :&nbsp;
                <select class="form-control form-control-sm" (change)="setTypeEvent(typeEvent.All,0,$event.target.value)">
                    <option  [selected]="eventUser==0" value="0">Tous</option>
                    <option value="{{employe.id}}" *ngFor="let employe of employes">{{employe.last_name}} {{employe.first_name}}</option>
                </select>
                </label>
            </div>
            <div #calendarScreen>
                <full-calendar #calendar deepChangeDetection="true" [options]="calendarOptions"   ></full-calendar>
            </div>
        </div>

    </div>
</div>

<div id="PlanningDownload">
    <img #calendarCanvas>
    <a #calendarDownloadLink></a>
</div>

<div mdbModal #modalEditEvent="mdb-modal" class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document" *ngIf="modalEditEvent && modalEditEvent.config.data.eventEdited">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" *ngIf="!modalEditEvent.config.data.eventEdited.id">Ajouter une entrée au planning</h4>
                <h4 class="modal-title w-100" *ngIf="modalEditEvent.config.data.eventEdited.id">Modification d'une entrée au planning</h4>
                <button type="button" class="close" data-dismiss="modal"
                        aria-label="Close" (click)="modalEditEvent.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="position-relative">
                        <mdb-date-picker [disabled]="!userService.isAdmin()" [(ngModel)]="modalEditEvent.config.data.eventEdited.dateStartEvent" (ngModelChange)="onDateStartEventChange()" [options]="datePickerOptions" [label]="'Date'" title="dateEvent" [placeholder]="'Date'" [locale]="'fr'" required></mdb-date-picker>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div><b>Type </b>:<br/>
                        <select [disabled]="!userService.isAdmin()" class="form-control form-control-sm mb-3" [(ngModel)]="modalEditEvent.config.data.eventEdited.typeEvent">
                          <option selected="selected" [ngValue]="typeEvent.Navigation">Navigation</option>
                          <option [ngValue]="typeEvent.Repos">Repos</option>
                          <option [ngValue]="typeEvent.CaisseToulon">Caisse Toulon</option>
                          <option [ngValue]="typeEvent.CaisseLaRade">Caisse Rade</option>
                          <option [ngValue]="typeEvent.CaisseLaLonde">Caisse La Londe</option>
                          <option [ngValue]="typeEvent.Chantier">Chantier</option>
                          <option [ngValue]="typeEvent.EntretienBateau">Entretien Bâteau</option>
                          <option [ngValue]="typeEvent.Formation">Formation</option>
                          <option [ngValue]="typeEvent.Ecole">Ecole</option>
                          <option [ngValue]="typeEvent.Reunion">Réunion</option>
                          <option [ngValue]="typeEvent.Direction">Direction</option>
                          <option [ngValue]="typeEvent.Presta">Prestation</option>
                          <option [ngValue]="typeEvent.Escale">Escale</option>
                          <option [ngValue]="typeEvent.Bureau">Bureau</option>
                          <option [ngValue]="typeEvent.Maladie">Maladie</option>
                          <option [ngValue]="typeEvent.Conges">Congés</option>
                          <option [ngValue]="typeEvent.Recuperation">Récupération</option>
                          <option [ngValue]="typeEvent.Affretement">Affrètement</option>
                          <option [ngValue]="typeEvent.RS">Réseaux sociaux</option>
                          <option [ngValue]="typeEvent.ReposAnticipe">Repos anticipé</option>
                          <option [ngValue]="typeEvent.Prospectus">Prospectus</option>
                          <option [ngValue]="typeEvent.FinContrat">Fin de contrat</option>
                          <option [ngValue]="typeEvent.Quai">Quai</option>
                          <option [ngValue]="typeEvent.Autre">Autre</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <b>Durée </b>:<br/>
                      <select [disabled]="!userService.isAdmin()" class="form-control form-control-sm mb-3" [(ngModel)]="modalEditEvent.config.data.eventEdited.durationEvent">
                        <option selected="selected" [ngValue]="durationEvent.All">Journée</option>
                        <option [ngValue]="durationEvent.Day">Journée*</option>
                        <option [ngValue]="durationEvent.Morning">Matinée</option>
                        <option [ngValue]="durationEvent.Afternoon">Après-midi</option>
                        <option [ngValue]="durationEvent.Evening">Soirée</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" *ngIf="modalEditEvent.config.data.eventEdited.typeEvent==typeEvent.Navigation">
                      <div><b>Ligne </b>:<br/>
                        <select [disabled]="!userService.isAdmin()" class="form-control form-control-sm" [(ngModel)]="modalEditEvent.config.data.eventEdited.ligne"
                                (change)="modalEditEvent.config.data.eventEdited.bateau = bateauService.getLigneById(modalEditEvent.config.data.eventEdited.ligne).bateau_default">
                          <option [selected]="modalEditEvent.config.data.eventEdited.ligne == null">Sélectionner une ligne</option>
                          <option [selected]="modalEditEvent.config.data.eventEdited.ligne==ligne.id" *ngFor="let ligne of lignes" [value]="ligne.id">{{ ligne.title }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="modalEditEvent.config.data.eventEdited.typeEvent==typeEvent.Navigation">
                      <div><b>Bateau </b>:<br/>
                        <select [disabled]="!userService.isAdmin()" class="form-control form-control-sm" [(ngModel)]="modalEditEvent.config.data.eventEdited.bateau">
                          <option [selected]="modalEditEvent.config.data.eventEdited.bateau == null">Sélectionner un bateau</option>
                          <option [selected]="modalEditEvent.config.data.eventEdited.bateau==bateau.id" *ngFor="let bateau of bateaux" [value]="bateau.id">{{ bateau.title }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="form-outline">
                        <label for="eventCommentaire" class="form-label"><mdb-icon fas icon="pencil-alt"></mdb-icon> Commentaire</label>
                        <textarea id="eventCommentaire" [disabled]="!userService.isAdmin()" class="form-control form-control-sm w-100" [(ngModel)]="modalEditEvent.config.data.eventEdited.commentaire"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-outline" *ngIf="userService.isAdmin()">
                        <label for="adminCommentaire" class="form-label"><mdb-icon fas icon="pencil-alt"></mdb-icon> Commentaire Administration <small>(Visible uniquement par l'administration)</small> : </label>
                        <textarea id="adminCommentaire" [disabled]="!userService.isAdmin()" class="form-control form-control-sm w-100" [(ngModel)]="modalEditEvent.config.data.eventEdited.adminComment"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div class="row mt-3">
                    <div class="col-md-6" *ngIf="userService.isAdmin()" >
                        <div class="row">
                           <div class="col-md-4">
                               <div class="mb-3"><b>Membres</b> : </div>
                           </div>
                            <div class="col-md-8">
                                <input
                                        class="form-control form-control-sm my-0 py-0"
                                        type="text"
                                        [(ngModel)]="searchEmployesText"
                                        placeholder="Rechercher un membre"
                                />
                            </div>
                        </div>
                        <div class="member-table-container">
                            <table class="table table-responsive-sm">
                                <tbody>
                                    <tr *ngFor="let employe of employesFiltered()">
                                        <td class="p-0">{{employe.first_name}} {{employe.last_name}}</td>
                                        <td class="p-0 text-right">
                                            <button
                                                    type="button"
                                                    mdbBtn
                                                    outline="true"
                                                    color="orange"
                                                    rounded="true"
                                                    size="sm"
                                                    class="px-2"
                                                    (click)="addEmployeEvent(employe)"
                                            >
                                                <mdb-icon fas icon="plus" class="mt-0"></mdb-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <mdb-card cascade="true" narrower="true">
                            <!--Card image-->
                            <div
                                    class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center flex-column"
                            >
                                <span class="white-text mx-3">Employés sélectionnés</span>
                                <div class="d-flex justify-content-between align-items-center mt-1 w-100"
                                     *ngIf="eventService.isEventNeedReferent(modalEditEvent.config.data.eventEdited)">
                                    <span class="w-50">Référent :</span>
                                    <select [disabled]="!userService.isAdmin()"  class="form-control form-control-sm" [(ngModel)]="modalEditEvent.config.data.eventEdited.employeRef">
                                        <option value="">Sélectionner</option>
                                        <option value="{{employe.employe.id}}"  *ngFor="let employe of this.modalEditEvent.config.data.eventEdited.employes">{{employe.employe.first_name}} {{employe.employe.last_name}}</option>
                                    </select>
                                </div>

                            </div>
                            <!--/Card image-->
                            <div class="px-4">
                                <div class="table-wrapper">
                                    <!--Table-->
                                    <table class="table mb-0">
                                        <!--Table body-->
                                        <tbody>
                                        <tr *ngFor="let employe of this.modalEditEvent.config.data.eventEdited.employes">
                                            <td class="p-0 pt-1">{{employe.employe.first_name}} {{employe.employe.last_name}}</td>
                                            <td class="p-0 pt-1">
                                                <div class="d-flex" style="align-items: center" *ngIf="eventService.isEventNeedHeuresSupp(this.modalEditEvent.config.data.eventEdited)">
                                                    <small style="white-space: nowrap">Heures Supp. :&nbsp;</small>
                                                    <input
                                                            class="form-control mb-1 form-control-sm"
                                                            type="number"
                                                            step = "0.25"
                                                            min="0"
                                                            [disabled]="!userService.isAdmin() &&
                                                            userService.isEmployeRefValidationLock(this.modalEditEvent.config.data.eventEdited) &&
                                                            !userService.isEmployeRef(this.modalEditEvent.config.data.eventEdited)"
                                                            [(ngModel)]="employe.time"
                                                            placeholder="Rechercher un membre"
                                                    />
                                                </div>

                                            </td>
                                            <td class="p-0 text-right" >
                                                <div *ngIf="userService.isAdmin()">
                                                    <button
                                                            (click)="removeEmployeFromEvent(employe)"
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="red"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                    >
                                                        <mdb-icon fas icon="times" class="mt-0"></mdb-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <!--Table body-->
                                    </table>
                                    <!--Table-->
                                </div>

                                <hr class="my-0" />

                            </div>
                        </mdb-card>
                    </div>
                </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div>
                    <label for="employeRefComment"><mdb-icon fas icon="pencil-alt"></mdb-icon> Commentaire Référent :</label>
                    <textarea
                      id="employeRefComment"
                      class="form-control w-100"
                      [(ngModel)]="modalEditEvent.config.data.eventEdited.employeRefComment"
                      [disabled]="!userService.isAdmin() &&
                                  userService.isEmployeRefValidationLock(this.modalEditEvent.config.data.eventEdited) &&
                                  !userService.isEmployeRef(this.modalEditEvent.config.data.eventEdited)"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Heures supplémentaires </label> :<br/>
                  <div class="form-check form-check-inline" *ngFor="let legende of modalEditEvent.config.data.eventEdited.legende"
                       mdbTooltip="{{legende.name}}" placement="top"
                  >
                    <input
                      class="form-check-input"
                      name="{{legende.acronym}}"
                      type="checkbox"
                      id="{{legende.acronym}}"
                      [checked]="legende.value"
                      [disabled]="!userService.isAdmin() &&
                                  userService.isEmployeRefValidationLock(this.modalEditEvent.config.data.eventEdited) &&
                                  !userService.isEmployeRef(this.modalEditEvent.config.data.eventEdited)"
                      (change)="legende.value = !legende.value" />
                    <label class="form-check-label" for="{{legende.acronym}}">{{legende.acronym}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
                <div>
                    <button type="button" *ngIf="userService.isAdmin()" mdbBtn color="secondary" size="sm"
                            class="waves-light" (click)="deleteEvent(this.modalEditEvent.config.data.eventEdited)"  mdbWavesEffect>Supprimer
                    </button>
                </div>
                <div>
                    <button type="button" mdbBtn color="light" size="sm"
                            class=" waves-light" data-dismiss="modal"
                            (click)="closeEvent()" mdbWavesEffect>Fermer
                    </button>
                    <button type="button" mdbBtn color="primary" size="sm"
                            class="waves-light" (click)="saveEvent()"  mdbWavesEffect>Enregistrer
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
<div mdbModal #modalDeleteEvent="mdb-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-danger" role="document" *ngIf="eventDelete !== undefined">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Etes vous certain de vouloir supprimer cet événement?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modalDeleteEvent.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Etes vous certain de vouloir supprimer {{ eventDelete.title }} ?</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="default" outline="true" (click)="modalDeleteEvent.hide()" mdbWavesEffect>Annuler
                </a>
                <a (click)="onDeleteEvent()" type="button" mdbBtn color="secondary"  data-dismiss="modal"
                   mdbWavesEffect>Oui, je suis certain</a>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalPlanningEvent="mdb-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Envoi d'une notification par email</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modalPlanningEvent.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <p>Sélectionner la plage de date à notifier :</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mdb-date-picker [(ngModel)]="dateStartPlanning" [options]="datePickerOptions" [label]="'Date de début'" title="dateStartPlanning" [placeholder]="'DD/MM/YYYY'" [locale]="'fr'"></mdb-date-picker>
          </div>
          <div class="col-md-6">
            <mdb-date-picker [(ngModel)]="dateEndPlanning" [options]="datePickerOptions" [label]="'Date de fin'" title="dateEndPlanning" [placeholder]="'DD/MM/YYYY'" [locale]="'fr'"></mdb-date-picker>
          </div>
        </div>
        <div>
          <p>Une notification de modification de planning sera envoyée aux personnes ayant une tâche affectée sur cette plage de dates.</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="blue" outline="true" (click)="modalPlanningEvent.hide()" mdbWavesEffect>
          Annuler
        </a>
        <a (click)="onSendPlanning()" *ngIf="!isSentPlanning" type="button" mdbBtn color="blue"  data-dismiss="modal" mdbWavesEffect>
          Envoyer
        </a>
        <a href="#" *ngIf="isSentPlanning" type="button" mdbBtn color="blue"  data-dismiss="modal" mdbWavesEffect>
          <mdb-icon fas icon="spinner" class="fa-pulse"></mdb-icon>
        </a>
      </div>
    </div>
  </div>
</div>
