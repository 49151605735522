<app-navigation *ngIf="!specialPage">
</app-navigation>

<main [ngClass]="{'py-0 px-0 my-0 mx-0': specialPage}">

    <app-notification></app-notification>

    <div [class.container-fluid]="!specialPage">
        <router-outlet></router-outlet>
    </div>
</main>

<app-footer *ngIf="!specialPage"></app-footer>
