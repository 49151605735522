<div class="card p-2">
  <div class="row">
    <div class="col-12 mb-4">
      <div #calendarScreen>
        <full-calendar #calendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
      </div>
    </div>

  </div>
</div>
