import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class LogService {
    constructor(public logger: NGXLogger) {
        this.log("FAIRCREW")
    }

    log(log: string){
        return this.logger.log(log);
    }

    info(log: string){
        return this.logger.info(log);
    }


    logService(log: string){
        return this.logger.log('🔧 Service :',log);
    }

    logHttpCallback(log:string, response?: any){
        let logApiRes = "";
        logApiRes = response !== undefined ? ": OK" : ": NOK";
        return this.logger.trace("🌐️🟢 API :",log, logApiRes);
    }

    logHttpCallbackError(log:string){
        return this.logger.error("🌐🔴 API : ",log);
    }

    logSession(log:string){
        return this.logger.info("🕒 Session :",log);
    }

    logInteraction(log:string){
        return this.logger.log("🖐️ Touch/Click :",log);
    }

    logNavigation(log:string){
        return this.logger.log("🧭 Navigate :",log);
    }

    logInput(log:string){
        return this.logger.log("⌨️️ Input :",log);
    }

    logMaintenance(log: string){
        return this.logger.warn("⚡️️ Maintenance :",log);
    }

}
