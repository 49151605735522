import {Component, OnInit, ViewChild} from '@angular/core';
import {Bateau} from "../../../models/Bateau";
import {Port} from "../../../models/Port";
import {Ligne} from "../../../models/Ligne";
import {ModalDirective} from "ng-uikit-pro-standard";
import {BateauService} from "../../../providers/Bateau.service";

@Component({
  selector: 'app-bateaux',
  templateUrl: './bateaux.component.html',
  styleUrls: ['./bateaux.component.scss']
})
export class BateauxComponent implements OnInit {
  public bateauEdited:Bateau;
  public bateauDelete:Bateau;
  public bateaux: Bateau[];
  public nomBateau:string;
  public capMaxBateau:string;

  public ports: Port[];
  public portEdited:Port;
  public portDelete:Port;

  public nomPort:string;
  public adressePort:string;

  public ligneEdited:Ligne;
  public ligneDelete:Ligne;

  public lignes: Ligne[];
  public nomLigne:string;
  public dureeLigne:string;
  public ligneDepart:string = "";
  public ligneArrivee:string = "";
  public bateau_default:string;

  getBateauById(id:number){
    return this.bateaux?.find(bateau=>bateau.id ==id);
  }

  getLigneById(id:number){
    return this.lignes?.find(ligne=>ligne.id ==id);
  }

  getPortById(id:number){
    return this.ports?.find(port=>port.id ==id);
  }

  getPortsMdbSelect(){
    return this.ports?.map(port => {return {value:port.id,label: port.title}});
  }


  @ViewChild('modalEditBateau') modalEditBateau: ModalDirective;
  @ViewChild('modalDeleteBateau') modalDeleteBateau: ModalDirective;

  @ViewChild('modalEditPort') modalEditPort: ModalDirective;
  @ViewChild('modalDeletePort') modalDeletePort: ModalDirective;

  @ViewChild('modalEditLigne') modalEditLigne: ModalDirective;
  @ViewChild('modalDeleteLigne') modalDeleteLigne: ModalDirective;

  private sorted = false;

  constructor(public bateauService : BateauService) { }

  ngOnInit(): void {
    this.getBateaux();
    this.getPorts();
    this.getLignes();

  }

  sortBy(by: string | any): void {
    this.bateaux.sort((a: any, b: any) => {
      if (a[by] < b[by]) {
        return this.sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return this.sorted ? -1 : 1;
      }

      return 0;
    });

    this.sorted = !this.sorted;
  }

  getBateaux(){
    this.bateauService.bateauxHttp().then((bateaux)=>{
      this.bateaux = bateaux;
    });
  }

  getPorts(){
    this.bateauService.portsHttp().then((ports)=>{
      this.ports = ports;
    });
  }

  getLignes(){
    this.bateauService.lignesHttp().then((lignes)=>{
      this.lignes = lignes;
    });
  }

  addBateau(){
    if(this.nomBateau !== "" && this.capMaxBateau !== ""){
      let addBateau=new Bateau();
      addBateau.cap_max=parseInt(this.capMaxBateau);
      addBateau.title=this.nomBateau;
      this.bateauService.addBateauxHttp(addBateau).then(()=>{
        this.getBateaux();
      }).finally(()=>{
        this.capMaxBateau="";
        this.nomBateau="";
      })
    }
  }
  editBateau(bateau:Bateau){
    this.bateauEdited=bateau;
    this.modalEditBateau.config.data.bateauEdit=JSON.parse(JSON.stringify(bateau));
  this.modalEditBateau.show();
  }
  deleteBateau(bateau:Bateau){
    this.bateauDelete=bateau;
    this.modalDeleteBateau.show();
  }
  onDeleteBateau(){
    this.bateauService.deleteBateauxHttp(this.bateauDelete).then(()=>{
      this.modalDeleteBateau.hide();
      this.getBateaux();
    })
  }
  saveEditBateau(){
    this.bateauEdited=this.modalEditBateau.config.data.bateauEdit;
    this.bateauService.editBateauxHttp(this.bateauEdited).then(()=>{
      this.getBateaux();
      this.modalEditBateau.hide();
    });

  }

  addPort(){
    if(this.nomPort !== ""){
      let addPort = new Port();
      addPort.adresse = this.adressePort;
      addPort.title = this.nomPort;
      this.bateauService.addPortsHttp(addPort).then(()=>{
        this.adressePort="";
        this.nomPort="";
        this.getPorts();
      })
    }
  }
  editPort(port:Port){
    this.portEdited=port;
    this.modalEditPort.config.data.portEdit=JSON.parse(JSON.stringify(port));
    this.modalEditPort.show();
  }
  deletePort(port:Port){
    this.portDelete=port;
    this.modalDeletePort.show();
  }
  onDeletePort(){
    this.bateauService.deletePortsHttp(this.portDelete).then(()=>{
      this.modalDeletePort.hide();
      this.getPorts();
    })
  }
  saveEditPort(){
    this.portEdited=this.modalEditPort.config.data.portEdit;
    this.bateauService.editPortsHttp(this.portEdited).then(()=>{
      this.getPorts();
      this.modalEditPort.hide();
    });

  }

  addLigne(){
    if(this.nomLigne !== "" && this.dureeLigne !== "" && this.ligneDepart !== "" && this.ligneArrivee !== ""&& this.bateau_default !== ""){
      let addLigne=new Ligne();
      addLigne.title = this.nomLigne;
      addLigne.duree = parseInt(this.dureeLigne);
      addLigne.depart = parseInt(this.ligneDepart);
      addLigne.arrivee = parseInt(this.ligneArrivee);
      addLigne.bateau_default = parseInt(this.bateau_default);
      this.nomLigne = "";
      this.dureeLigne= "";
      this.ligneDepart = "";
      this.ligneArrivee = "";
      this.bateau_default = "";

      this.bateauService.addLignesHttp(addLigne).then(()=>{
        this.getLignes();
      })
    }
  }

  editLigne(ligne:Ligne){
    this.ligneEdited=ligne;
    this.modalEditLigne.config.data.ligneEdit=JSON.parse(JSON.stringify(ligne));
    this.modalEditLigne.show();
  }
  deleteLigne(ligne:Ligne){
    this.ligneDelete=ligne;
    this.modalDeleteLigne.show();
  }
  onDeleteLigne(){
    this.bateauService.deleteLignesHttp(this.ligneDelete).then(()=>{
      this.modalDeleteLigne.hide();
      this.getLignes();
    })
  }
  saveEditLigne(){
    this.ligneEdited=this.modalEditLigne.config.data.ligneEdit;
    this.bateauService.editLignesHttp(this.ligneEdited).then(()=>{
      this.getLignes();
      this.modalEditLigne.hide();
    });
  }
}
