import { Component, OnInit } from '@angular/core';
import {User} from "../../../models/User";
import {UserService} from "../../../providers/User.service";
import {AuthenticationService} from "../../../providers/Authentication.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public user:User;

  constructor(public authenticationService: AuthenticationService, public userService: UserService) { }

  ngOnInit(): void {
    this.authenticationService.getAccessUserId().toPromise().then((userId)=>{
      this.userService.getUserHttp(parseInt(userId)).then((user)=>{
        this.user = user;
      })
    });
  }

}
