import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorageService {

    /**
     * Get token
     * @returns {Observable<string>}
     */
    public getToken(): Observable<string> {
        const token: string = <string>localStorage.getItem('token');
        return of(token);
    }

    /**
     * Get token
     * @returns {Observable<string>}
     */
    public getUserId(): Observable<string> {
        const userId: string = <string>localStorage.getItem('userid');
        return of(userId);
    }

    /**
     * Set token
     * @returns {TokenStorage}
     */
    public setToken(token: string,userid:string): TokenStorageService {
        localStorage.setItem('token', token);
        localStorage.setItem('userid', userid);

        return this;
    }


    /**
     * Remove tokens
     */
    public clear() {
        localStorage.removeItem('token');
    }
}
