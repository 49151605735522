import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Contrat} from "../../../enums/Contrat";
import {Fonction} from "../../../enums/Fonction";
import {AuthenticationService} from "../../../providers/Authentication.service";
import {UserService} from "../../../providers/User.service";
import {User} from "../../../models/User";


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) sidenav: ElementRef;

  public clicked: boolean;
  public user:User;

  constructor(public authenticationService: AuthenticationService,public userService: UserService) {
    this.clicked = this.clicked !== undefined;
  }

  ngOnInit() {
    this.authenticationService.getAccessUserId().toPromise().then((userId)=>{
      this.userService.getUserHttp(parseInt(userId)).then((user)=>{
        this.user = user;
        this.userService.user = user;
      })
    });

  }

  logout() {
    this.authenticationService.logout();
  }

  setClicked(val: boolean): void {
    this.clicked = val;
  }

}
