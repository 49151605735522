<div class="row">
    <div class="col-md-12">

        <!--Card-->
        <mdb-card cascade="true" narrower="true">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header light-blue lighten-1">
                <h4 class="h4-responsive">Mon profil</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">

                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <!--Panel-->
                            <mdb-card class="mb-3 mt-3">
                                <mdb-card-body>
                                    <h4 class="card-title">Modifier mon mot de passe</h4>
                                    <p class="card-text mt-3 mb-4">Saisir mon ancien mot de passe, puis le nouveau</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p class="label mb-0">Mot de passe actuel</p>
                                            <div class="mb-2">
                                                <input type="password" readonly id="MdpActuel" class="form-control pt-0" mdbInput>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="label mb-0">Nouveau mot de passe</p>
                                            <div class="mb-2">
                                                <input type="password" readonly id="NewMdp" class="form-control pt-0" mdbInput>
                                            </div>
                                            <p class="label mb-0">Confirmer le nouveau mot de passe</p>
                                            <div class="mb-2">
                                                <input type="password" readonly id="ConfNewMdp" class="form-control pt-0" mdbInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center mt-3">
                                        <button disabled="disabled" type="button" mdbBtn gradient="blue" rounded="true" class="waves-light" mdbWavesEffect>
                                            Mettre à jour mon mot de passe
                                        </button>
                                    </div>
                                </mdb-card-body>
                            </mdb-card>
                            <!--/.Panel-->
                        </div>
                    </div>
                    <!--First row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.last_name}}" readonly="readonly" type="text" id="Nom" class="form-control" mdbInput>
                                <label for="Nom">Nom</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.first_name}}" readonly="readonly" type="text" id="Prenom" class="form-control" mdbInput>
                                <label for="Prenom">Prénom</label>
                            </div>
                        </div>

                        <!--Third column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.numero}}" type="text" id="Telephone" class="form-control" mdbInput>
                                <label for="Telephone">Téléphone</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.mail}}" type="text" id="Email" class="form-control" mdbInput>
                                <label for="Email">Email</label>
                            </div>
                        </div>
                    </div>
                    <!--/.First row-->

                    <!--Second row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-6">
                            <div class="md-form">
                                <input placeholder="{{user.adresse}}" type="text" id="Adresse" class="form-control" mdbInput>
                                <label for="Adresse">Adresse</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.cp}}" type="text" id="Cp" class="form-control" mdbInput>
                                <label for="Cp">Code Postal</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.ville}}" type="text" id="Ville" class="form-control" mdbInput>
                                <label for="Ville">Ville</label>
                            </div>
                        </div>
                    </div>
                    <!--/.Second row-->

                    <!--Third row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.dateNaissance| date: 'dd/MM/yyyy'}}" readonly="readonly" type="text" id="DateNaissance" class="form-control" mdbInput>
                                <label for="DateNaissance">Date de naissance</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.fonction}}" readonly="readonly" type="text" id="Fonction" class="form-control" mdbInput>
                                <label for="DateNaissance">Fonction</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.portPref}}" readonly="readonly" type="text" id="portPrefere" class="form-control" mdbInput>
                                <label for="portPrefere">Port préféré</label>
                            </div>
                        </div>
                        <!--Third column-->
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.contrat}}" readonly="readonly" type="text" id="Contrat" class="form-control" mdbInput>
                                <label for="Contrat">Contrat</label>
                            </div>
                        </div>

                    </div>
                    <!--/.Third row-->

                    <!--Fourth row-->
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.finContrat}}" readonly="readonly" type="text" id="FinContrat" class="form-control" mdbInput>
                                <label for="finContrat">Date fin de contrat</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.heures}}" readonly="readonly" type="text" id="Heures" class="form-control" mdbInput>
                                <label for="Heures">Nombre d'heures</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="md-form">
                                <input placeholder="{{user.heuresSup}}" readonly="readonly" type="text" id="HeuresSup" class="form-control" mdbInput>
                                <label for="HeuresSup">Nombre d'heures sup</label>
                            </div>
                        </div>
                    </div>
                    <!--/.Fourth row-->
                    <!--
                    <div class="row">
                        <div class="col-md-3">
                            <p><b>Jours de repos préférés:</b></p>
                            <ul>
                                <li *ngFor="let jour of user.conges">{{jour}}</li>
                            </ul>
                        </div>

                    </div>
                    -->

                    <div class="text-center mt-3">
                        <button type="button" mdbBtn gradient="blue" rounded="true" class="waves-light" mdbWavesEffect>
                            Mettre à jour mes informations
                        </button>
                    </div>

                </form>
                <!--Contact Form-->

            </mdb-card-body>
            <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->


    </div>
</div>
