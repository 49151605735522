import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BateauxComponent } from './bateaux/bateaux.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AgmCoreModule} from "@agm/core";
import {CalendarModule} from "angular-calendar";
import { EquipagesComponent } from './equipages/equipages.component';
import {FullCalendarModule} from "@fullcalendar/angular";


//calendar
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { PlanningComponent } from './planning/planning.component'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import { ProfileComponent } from './profile/profile.component';
import { GlobalComponent } from './global/global.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import {AuthenticationService} from "../../providers/Authentication.service";
import {TokenStorageService} from "../../providers/TokenStorage.service";
import {ToastModule} from "ng-uikit-pro-standard";
import {FcEventComponent} from "../fc-components/fc-event/fc-event.component";
import { HeuresSuppComponent } from './heures-supp/heures-supp.component';
import { CongesComponent } from './conges/conges.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  resourceTimelinePlugin
]);


@NgModule({
  declarations: [
    BateauxComponent,
    EquipagesComponent,
    PlanningComponent,
    ProfileComponent,
    GlobalComponent,
    SettingsComponent,
    LoginComponent,
    FcEventComponent,
    HeuresSuppComponent,
    CongesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCb44fZMVNTqsA7phK5chbOolMgsJl9mFw'
    }),
    FullCalendarModule // register FullCalendar with you app
  ],
  exports : []
})
export class FcViewsModule { }
