import {TokenStorageService} from "../providers/TokenStorage.service";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthenticationService} from "../providers/Authentication.service";
import {Injectable} from "@angular/core";
import {tap} from "rxjs/operators";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(public auth: AuthenticationService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log(next);
        const token =localStorage.getItem('token');
        if(token){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request).pipe( tap(() => {},
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (![401,403].includes(err.status)) {
                        return;
                    }
                    this.auth.logout();
                }
            }));
    }



}
