import {Injectable} from "@angular/core";
import {environment} from "../environments/environment";


export class API {

    static GetUrl(method: string): string {
        return environment.backendUrl + method;
    }

    static GetTokenUrl(): string {
        return API.GetUrl('/jwt-auth/v1/token');
    }

    static GetTokenValidateUrl(): string {
        return API.GetUrl('/jwt-auth/v1/token/validate');
    }

    static GetBateauxUrl(): string {
        return API.GetUrl('/wp/v2/bateau');
    }

    static GetEventsUrl(): string {
        return API.GetUrl('/wp/v2/event');
    }

    static GetEventsWithParamsUrl(): string {
        return API.GetUrl('/event/v2/by-dates-and-type');
    }

  static GetHeuresSuppWithParamsUrl(): string {
    return API.GetUrl('/event/v2/heures-supp');
  }

    static GetPortsUrl(): string {
        return API.GetUrl('/wp/v2/port');
    }

    static GetLignesUrl(): string {
        return API.GetUrl('/wp/v2/ligne');
    }
    static GetUsersUrl(): string {
        return API.GetUrl('/wp/v2/users');
    }
    static GetEmployesUrl(): string {
        return API.GetUrl('/wp/v2/users');
    }
  static GetSendPlanningUrl(): string {
    return API.GetUrl('/planning/v2/send/');
  }
}
