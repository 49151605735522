<!--Double navigation-->
<header>

    <!-- Sidebar navigation -->
    <mdb-sidenav #sidenav class=" fixed" [fixed]="true" >
        <links>
            <!-- Side navigation links -->
            <li>
              <div class="logo-wrapper waves-light">
                <a routerLink="planning">
                  <bca-logo></bca-logo>
                </a>
              </div>
            </li>
            <li>
                <ul class="collapsible collapsible-accordion" *ngIf="user">
                    <mdb-squeezebox [multiple]="false" aria-multiselectable="false">
                        <mdb-item class="no-collase" >
                            <mdb-item-head mdbWavesEffect routerLink="planning">
                                <mdb-icon fas icon="calendar" class="mr-1"></mdb-icon> Planning
                            </mdb-item-head>
                        </mdb-item>
                        <mdb-item class="no-collase" *ngIf="user.userRole =='admin'">
                            <mdb-item-head mdbWavesEffect routerLink="bateaux">
                                <mdb-icon fas icon="anchor" class="mr-1"></mdb-icon> Bateaux - Ports - Lignes
                            </mdb-item-head>
                            <mdb-item-body></mdb-item-body>
                        </mdb-item>
                        <mdb-item class="no-collase" *ngIf="user.userRole =='admin'">
                            <mdb-item-head mdbWavesEffect routerLink="equipages">
                                <mdb-icon fas icon="users" class="mr-1"></mdb-icon> Salariés
                            </mdb-item-head>
                            <mdb-item-body></mdb-item-body>
                        </mdb-item>
                        <mdb-item class="no-collase" *ngIf="user.userRole =='admin'">
                          <mdb-item-head mdbWavesEffect routerLink="heures-supp">
                            <mdb-icon fas icon="clock" class="mr-1"></mdb-icon> Heures Supp.
                          </mdb-item-head>
                          <mdb-item-body></mdb-item-body>
                        </mdb-item>
                        <mdb-item class="no-collase" *ngIf="user.userRole =='admin'">
                          <mdb-item-head mdbWavesEffect routerLink="conges">
                            <mdb-icon fas icon="clock" class="mr-1"></mdb-icon> Congés
                          </mdb-item-head>
                          <mdb-item-body></mdb-item-body>
                        </mdb-item>
                    </mdb-squeezebox>
                </ul>
            </li>
            <!--/. Side navigation links -->
        </links>

    </mdb-sidenav>
    <!--/. Sidebar navigation -->


    <!-- Navbar -->
    <mdb-navbar SideClass="navbar navbar-dark navbar-expand-md scrolling-navbar double-nav" [containerInside]="false">
        <navlinks class="navbar-container">
            <!-- SideNav slide-out button -->
            <div class="float-xs-left">
                <a (click)="sidenav.show()" class="button-collapse-non-fixed">
                    <mdb-icon fas icon="bars"></mdb-icon>
                </a>
            </div>
            <!--/. SideNav slide-out button -->
        </navlinks>
        <logo>
            <!-- Breadcrumb-->
            <div class="breadcrumbs breadcrumb-dn mr-auto">
                <logo>
                    <!-- Logo -->
                        <div class="logo-wrapper waves-light">

                        </div>
                    <!--/. Logo -->
                </logo>
            </div>
            <!--/. Breadcrumb-->
        </logo>
        <navlinks>
            <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav" *ngIf="user">
                <li class="nav-item dropdown btn-group" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                        <mdb-icon fas icon="user" class="mr-1"></mdb-icon>
                        <span class="clearfix d-none d-sm-inline-block">Bonjour {{user.first_name}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
                        <a class="dropdown-item" routerLink="profile">Mon compte</a>
                        <a class="dropdown-item" (click)="logout()">Deconnexion</a>
                    </div>
                </li>
            </ul>

        </navlinks>

    </mdb-navbar>
    <!--/. Navbar -->

</header>
<!--/.Double navigation-->
