<div class="card p-2">
    <div class="row">
        <div class="col-12 mb-4">
            <full-calendar [options]="calendarGlobal"></full-calendar>
        </div>
        <div class="col-12">
            <button type="button" mdbBtn color="red" mdbWavesEffect>Exporter le tableau en PDF</button> <button type="button" mdbBtn color="green" mdbWavesEffect>Exporter le tableau en Excel</button>
        </div>
    </div>
</div>



