<div class="row mt-3">
    <div class="col-md-12">
        <mdb-card-body cascade="true" >
            <!--Basic example-->
            <!-- Nav Tabs -->
            <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3 blue'" [contentClass]="'card'">
                <!--Panel 1-->
                <mdb-tab heading="Liste des bateaux" >
                    <div class="row">
                        <div class="col-4">
                            <div class="md-form">
                                <input mdbInput type="text" [(ngModel)]="nomBateau" id="NomBateau" class="form-control">
                                <label for="NomBateau" class="">Saisir un nom</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="md-form">
                                <input mdbInput type="number" [(ngModel)]="capMaxBateau" id="CapMax" class="form-control">
                                <label for="CapMax" class="">Saisir une capacité maximale</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="md-form"><button  (click)="addBateau()" mdbBtn color="pink" >
                                <mdb-icon fas icon="plus" class="mr-2"></mdb-icon> Ajouter un nouveau bateau</button></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <br>
                            <mdb-card cascade="true" narrower="true">
                                <!--Card image-->
                                <div
                                        class="view view-cascade gradient-card-header grey narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"
                                >
                                    <a href="" class="white-text mx-3">Tous les bateaux</a>
                                </div>
                                <!--/Card image-->

                                <div class="px-4">
                                    <div class="table-wrapper">
                                        <!--Table-->
                                        <table  mdbTable small="true" striped="true">
                                            <!--Table head-->
                                            <thead>
                                            <tr>
                                                <th>
                                                    <a (click)="sortBy('id')"
                                                    >Id
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a (click)="sortBy('title')"
                                                    >Nom
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a (click)="sortBy('cap_max')"
                                                    >Capacité Max.
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a (click)="sortBy('date')"
                                                    >Date
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                              <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <!--Table head-->

                                            <!--Table body-->
                                            <tbody>
                                            <tr mdbTableCol  *ngFor="let bateau of bateaux">
                                                <td>{{ bateau.id }}</td>
                                                <td>{{ bateau.title }}</td>
                                                <td>{{ bateau.cap_max }}</td>
                                                <td>{{ bateau.date | date: 'dd/MM/yyyy' }}</td>
                                                <td><div>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="blue"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            data-toggle="modal"
                                                            data-target="#EditBateau"
                                                            (click)="editBateau(bateau)"
                                                            mdbWavesEffect
                                                    >
                                                        <mdb-icon fas icon="pencil-alt" class="mt-0"></mdb-icon>
                                                    </button>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="red"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            data-toggle="modal"
                                                            data-target="#DeleteBateau"
                                                            (click)="deleteBateau(bateau)"
                                                    >
                                                        <mdb-icon fas icon="times" class="mt-0"></mdb-icon>
                                                    </button>
                                                </div></td>
                                            </tr>
                                            </tbody>
                                            <!--Table body-->
                                        </table>
                                        <!--Table-->
                                    </div>

                                    <hr class="my-0" />

                                </div>
                            </mdb-card>

                        </div>
                    </div>
                </mdb-tab>
                <!--Panel 2-->
                <mdb-tab heading="Liste des ports">
                    <div class="row">
                        <div class="col-3">
                            <div class="md-form">
                                <input [(ngModel)]="nomPort" mdbInput type="text" id="NomPort" class="form-control">
                                <label  for="NomPort" class="">Saisir un nom</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="md-form">
                                <input [(ngModel)]="adressePort" mdbInput type="text" id="AdressePort" class="form-control">
                                <label for="AdressePort" class="">Saisir une adresse</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="md-form"><button (click)="addPort()" mdbBtn color="pink"  mdbWavesEffect>
                                <mdb-icon fas icon="plus" class="mr-2"></mdb-icon> Ajouter un nouveau port</button></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <br>
                            <mdb-card cascade="true" narrower="true">
                                <!--Card image-->
                                <div
                                        class="view view-cascade gradient-card-header grey narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"
                                >
                                    <a href="" class="white-text mx-3">Tous les ports</a>
                                </div>
                                <!--/Card image-->

                                <div class="px-4">
                                    <div class="table-wrapper">
                                        <!--Table-->
                                        <table mdbTable small="true" striped="true">
                                            <!--Table head-->
                                            <thead>
                                            <tr>
                                                <th>
                                                    <a (click)="sortBy('id')"
                                                    >Id
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a (click)="sortBy('title')"
                                                    >Nom
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a>Adresse
                                                    </a>
                                                </th>
                                                <th>
                                                    <a (click)="sortBy('date')"
                                                    >Date
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                              <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <!--Table head-->

                                            <!--Table body-->
                                            <tbody>
                                            <tr mdbTableCol *ngFor="let port of ports">
                                                <td>{{ port.id }}</td>
                                                <td>{{ port.title }}</td>
                                                <td class="text-left">{{ port.adresse }}</td>
                                                <td>{{ port.date | date: 'dd/MM/yyyy' }}</td>
                                                <td><div class="d-flex">
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="blue"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            data-toggle="modal"
                                                            data-target="#EditPort"
                                                            (click)="editPort(port)"
                                                            mdbWavesEffect
                                                    >
                                                        <mdb-icon fas icon="pencil-alt" class="mt-0"></mdb-icon>
                                                    </button>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="red"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            data-toggle="modal"
                                                            data-target="#DeletePort"
                                                            (click)="deletePort(port)"
                                                    >
                                                        <mdb-icon fas icon="times" class="mt-0"></mdb-icon>
                                                    </button>
                                                </div></td>
                                            </tr>
                                            </tbody>
                                            <!--Table body-->
                                        </table>
                                        <!--Table-->
                                    </div>

                                    <hr class="my-0" />

                                </div>
                            </mdb-card>

                        </div>
                    </div>
                </mdb-tab>
                <!--Panel 3-->
                <mdb-tab heading="Liste des lignes">
                    <div class="row">
                        <div class="col-3">
                            <div class="md-form">
                                <input [(ngModel)]="nomLigne" mdbInput type="text" id="NomLigne" class="form-control">
                                <label for="NomLigne" class="">Saisir un nom</label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="md-form">
                                <select class="browser-default custom-select" [(ngModel)]="ligneDepart" >
                                    <option [selected]="ligneDepart==''" disabled>Départ</option>
                                    <option [selected]="ligneDepart==port.id" *ngFor="let port of ports" [value]="port.id">{{ port.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="md-form">
                                <select class="browser-default custom-select" [(ngModel)]="ligneArrivee">
                                    <option [selected]="ligneArrivee==''" disabled>Arrivée</option>
                                    <option [selected]="ligneArrivee==port.id" *ngFor="let port of ports" [value]="port.id">{{ port.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="md-form">
                                <input [ngModel]="dureeLigne" mdbInput type="number" id="DureeLigne" class="form-control">
                                <label for="DureeLigne" class="">Durée en minutes</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="md-form"><button (click)="addLigne()" mdbBtn color="pink" mdbWavesEffect>
                                <mdb-icon fas icon="check" class="mr-2"></mdb-icon> Ajouter une ligne</button></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <br>
                            <mdb-card cascade="true" narrower="true">
                                <!--Card image-->
                                <div
                                        class="view view-cascade gradient-card-header grey narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"
                                >
                                    <a href="" class="white-text mx-3">Toutes les lignes</a>
                                </div>
                                <!--/Card image-->

                                <div class="px-4">
                                    <div class="table-wrapper">
                                        <!--Table-->
                                        <table mdbTable small="true" striped="true">
                                            <!--Table head-->
                                            <thead>
                                            <tr>
                                                <th>
                                                    <a (click)="sortBy('id')"
                                                    >Id
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a (click)="sortBy('title')"
                                                    >Nom
                                                        <mdb-icon fas icon="sort" class="ml-1"></mdb-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    <a>Départ
                                                    </a>
                                                </th>
                                                <th>
                                                    <a>Arrivée
                                                    </a>
                                                </th>
                                                <th>
                                                    <a>Bateau par défaut
                                                    </a>
                                                </th>
                                              <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <!--Table head-->

                                            <!--Table body-->
                                            <tbody>
                                            <tr *ngFor="let ligne of lignes">
                                                <td>{{ ligne.id }}</td>
                                                <td>{{ ligne.title }}</td>
                                                <td>{{getPortById(ligne.depart)?.title}}</td>
                                                <td>{{getPortById(ligne.arrivee)?.title}}</td>
                                                <td>{{getBateauById(ligne.bateau_default)?.title}}</td>
                                                <td><div>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="blue"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            data-toggle="modal"
                                                            data-target="#EditLigne"
                                                            (click)="editLigne(ligne)"
                                                            mdbWavesEffect
                                                    >
                                                        <mdb-icon fas icon="pencil-alt" class="mt-0"></mdb-icon>
                                                    </button>
                                                    <button
                                                            type="button"
                                                            mdbBtn
                                                            outline="true"
                                                            color="red"
                                                            rounded="true"
                                                            size="sm"
                                                            class="px-2"
                                                            data-toggle="modal"
                                                            data-target="#DeleteLigne"
                                                            (click)="deleteLigne(ligne)"
                                                    >
                                                        <mdb-icon fas icon="times" class="mt-0"></mdb-icon>
                                                    </button>
                                                </div></td>
                                            </tr>
                                            </tbody>
                                            <!--Table body-->
                                        </table>
                                        <!--Table-->
                                    </div>

                                    <hr class="my-0" />

                                </div>
                            </mdb-card>

                        </div>
                    </div>
                </mdb-tab>
            </mdb-tabset>


        </mdb-card-body>
    </div>
</div>
<div mdbModal #modalEditBateau="mdb-modal" class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100">Modifier les infos du bateau</h4>
                <button type="button" class="close" data-dismiss="modal"
                        aria-label="Close" (click)="modalEditBateau.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="modalEditBateau.config.data.bateauEdit !== undefined">
                <p>Nom :<br/>
                <input type="text" [(ngModel)]="modalEditBateau.config.data.bateauEdit.title" class="form-control pt-0" mdbInput></p>
                <p>Capacité maximale :<br/>
                <input type="number" [(ngModel)]="modalEditBateau.config.data.bateauEdit.cap_max" class="form-control pt-0" mdbInput></p>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" size="sm"
                        class=" waves-light" data-dismiss="modal"
                        (click)="modalEditBateau.hide()" mdbWavesEffect>Fermer
                </button>
                <button type="button" mdbBtn color="primary" size="sm"
                        class="waves-light" (click)="saveEditBateau()"  mdbWavesEffect>Enregistrer

                </button>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalDeleteBateau="mdb-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-danger modal-dialog-scrollable" role="document" *ngIf="bateauDelete !== undefined">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Etes vous certain de vouloir supprimer ce bateau ?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modalDeleteBateau.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Etes vous certain de vouloir supprimer {{ bateauDelete.title }} ?</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="default" outline="true" (click)="modalDeleteBateau.hide()" mdbWavesEffect>Annuler
                </a>
                <a (click)="onDeleteBateau()" type="button" mdbBtn color="secondary"  data-dismiss="modal"
                    mdbWavesEffect>Oui, je suis certain</a>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalEditPort="mdb-modal" class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100">Modifier les infos du port</h4>
                <button type="button" class="close" data-dismiss="modal"
                        aria-label="Close" (click)="modalEditPort.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="modalEditPort.config.data.portEdit !== undefined">
                <p>Nom :
                <input type="text" [(ngModel)]="modalEditPort.config.data.portEdit.title" class="form-control pt-0" mdbInput></p>
                <p>Adresse:
                    <input type="text" [(ngModel)]="modalEditPort.config.data.portEdit.adresse" class="form-control pt-0" mdbInput></p>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" size="sm"
                        class=" waves-light" data-dismiss="modal"
                        (click)="modalEditPort.hide()" mdbWavesEffect>Fermer
                </button>
                <button type="button" mdbBtn color="primary" size="sm"
                        class="waves-light" (click)="saveEditPort()"  mdbWavesEffect>Enregistrer

                </button>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalDeletePort="mdb-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger modal-dialog-scrollable" role="document" *ngIf="portDelete !== undefined">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Etes vous certain de vouloir supprimer ce port ?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modalDeletePort.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Etes vous certain de vouloir supprimer {{ portDelete.title }} ?</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="default" outline="true" (click)="modalDeletePort.hide()" mdbWavesEffect>Annuler
                </a>
                <a (click)="onDeletePort()" type="button" mdbBtn color="secondary"  data-dismiss="modal"
                   mdbWavesEffect>Oui, je suis certain</a>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalEditLigne="mdb-modal" class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100">Modifier les infos de la ligne</h4>
                <button type="button" class="close" data-dismiss="modal"
                        aria-label="Close" (click)="modalEditLigne.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="modalEditLigne.config.data.ligneEdit !== undefined">
                <p>Nom :
                    <input type="text" [(ngModel)]="modalEditLigne.config.data.ligneEdit.title" class="form-control pt-0" mdbInput></p>

                <p>Durée en minutes:
                    <input type="number" [(ngModel)]="modalEditLigne.config.data.ligneEdit.duree" class="form-control pt-0" mdbInput></p>
                <div class="mb-2">
                    <b>Port départ</b>:<br/>
                    <select class="browser-default custom-select" [(ngModel)]="modalEditLigne.config.data.ligneEdit.depart">
                        <option disabled>Port de départ</option>
                        <option [selected]="modalEditLigne.config.data.ligneEdit.depart==port.id" *ngFor="let port of ports" [value]="port.id">{{ port.title }}</option>
                    </select>
                </div>
                <div>
                    <b>Port arrivée</b>:<br/>
                    <select class="browser-default custom-select" [(ngModel)]="modalEditLigne.config.data.ligneEdit.arrivee">
                        <option disabled>Port d'arrivée</option>
                        <option [selected]="modalEditLigne.config.data.ligneEdit.arrivee==port.id" *ngFor="let port of ports" [value]="port.id">{{ port.title }}</option>
                    </select>
                </div>
                <div>
                    <b>Bateau par défaut</b>:<br/>
                    <select class="browser-default custom-select" [(ngModel)]="modalEditLigne.config.data.ligneEdit.bateau_default">
                        <option disabled>Bateau par défaut</option>
                        <option [selected]="modalEditLigne.config.data.ligneEdit.bateau_default==bateau.id" *ngFor="let bateau of bateaux" [value]="bateau.id">{{ bateau.title }}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" size="sm"
                        class=" waves-light" data-dismiss="modal"
                        (click)="modalEditLigne.hide()" mdbWavesEffect>Fermer
                </button>
                <button type="button" mdbBtn color="primary" size="sm"
                        class="waves-light" (click)="saveEditLigne()"  mdbWavesEffect>Enregistrer

                </button>
            </div>
        </div>
    </div>
</div>
<div mdbModal #modalDeleteLigne="mdb-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger modal-dialog-scrollable" role="document" *ngIf="ligneDelete !== undefined">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Etes vous certain de vouloir supprimer cette ligne?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modalDeleteLigne.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Etes vous certain de vouloir supprimer {{ ligneDelete.title }} ?</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="default" outline="true" (click)="modalDeleteLigne.hide()" mdbWavesEffect>Annuler
                </a>
                <a (click)="onDeleteLigne()" type="button" mdbBtn color="secondary"  data-dismiss="modal"
                   mdbWavesEffect>Oui, je suis certain</a>
            </div>
        </div>
    </div>
</div>
