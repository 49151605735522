<section class="view intro intro-2 ">
        <div class="full-bg-img rgba-stylish-strong mask flex-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

                        <!--Form with header-->
                        <mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-title: none; visibility: visible;">
                            <mdb-card-body>

                                <!--Header-->
                                <div class="form-header indigo">
                                    <h3>Gestion BCA Groupe</h3>
                                </div>

                                <!--Body-->
                                <div class="md-form">
                                    <span  class="white-text"><mdb-icon far icon="envelope"></mdb-icon> Adresse email</span>
                                    <input type="text" id="orangeForm-email" [(ngModel)]="usertitle" class="form-control white-text" mdbInput>
                                    <span class="text-danger" *ngIf="isloging && usertitle == ''">Le nom d'utilisateur est vide.</span>
                                </div>

                                <div class="md-form">
                                    <span  class="white-text"><mdb-icon fas icon="lock"></mdb-icon> Mot de passe</span>
                                    <input type="password"  id="orangeForm-pass" [(ngModel)]="password" class="form-control white-text" mdbInput>
                                    <span class="text-danger" *ngIf="isloging && password == ''">Le mot de passe est vide.</span>
                                </div>
                                <div *ngIf="error" class="text-danger">{{error}}</div>
                                <div class="text-center">
                                    <button mdbBtn color="cyan" size="lg" class="waves-effect waves-light" mdbWavesEffect (click)="login()">
                                        <span *ngIf="!isloging">Se connecter</span>
                                        <mdb-icon *ngIf="isloging" fas icon="spinner" class="fa-pulse"></mdb-icon>
                                    </button>
                                </div>

                            </mdb-card-body>
                        </mdb-card>
                        <!--/Form with header-->

                    </div>
                </div>
            </div>
        </div>
    </section>

