import {Component, OnInit, ViewChild} from '@angular/core';
import {Bateau} from "../../../models/Bateau";
import {User} from "../../../models/User";
import {CalendarOptions, FullCalendarComponent} from "@fullcalendar/angular";
import frLocale from "@fullcalendar/core/locales/fr";
import {BateauService} from "../../../providers/Bateau.service";
import {EventService} from "../../../providers/Event.service";
import {UserService} from "../../../providers/User.service";
import {TypeEvent} from "../../../enums/TypeEvent";
import * as moment from "moment/moment";

@Component({
  selector: 'app-conges',
  templateUrl: './conges.component.html',
  styleUrls: ['./conges.component.scss']
})
export class CongesComponent implements OnInit {

  bateaux: Bateau[];
  users: User[];
  selectedBateau:Bateau;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public calendarOptions: CalendarOptions = {
    schedulerLicenseKey: null,
    initialView: 'resourceTimelineMonth',
    locale:frLocale,
    allDaySlot : true,
    filterResourcesWithEvents: true,
    slotDuration: {days: 1},
    slotLabelFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
    resourceAreaWidth:200,
    dayHeaderFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
    resourceAreaHeaderContent: "Employés"
  };

  constructor( public bateauService : BateauService,
               public eventService : EventService,
               public userService : UserService) { }

  ngOnInit(): void {
    const me = this;
    this.getUsers().then(()=>{
      this.calendarOptions.resourceOrder ='title,id';
      this.calendarOptions.resources = function (info, successCallback, failureCallback) {
        successCallback(
          me.users.map(function(user) {
            return {
              id: user.id.toString(),
              title: user.last_name.toUpperCase()+ " " + user.first_name
            }
          })
        );
      }
    })

    this.getBateaux().then(() => {
      this.selectedBateau = null;
      this.calendarOptions.events = function(info, successCallback, failureCallback) {
        const eventArgs = {
          'start': info.start.valueOf(),
          'end': info.end.valueOf(),
          'type': TypeEvent.All,
          'user': null,
          'bateau':null
        };
        me.eventService.eventsHttp(eventArgs).then(
          (events)=>{
            const typesConges = [TypeEvent.Conges, TypeEvent.Maladie, TypeEvent.Repos, TypeEvent.Recuperation, TypeEvent.ReposAnticipe, TypeEvent.FinContrat];
            successCallback(
              events.filter((e)=>typesConges.includes(e.typeEvent as TypeEvent)).map(function(eventEl) {
                let start = moment(eventEl.dateStartEvent+' '+ eventEl.heureDebut, "YYYY-MM-DD hh:mm").toISOString();
                let end = moment(eventEl.dateEndEvent+' '+ eventEl.heureFin, "YYYY-MM-DD hh:mm").toISOString();
                return {
                  start: start,
                  end: end,
                  allDay : true,
                  resourceIds: eventEl.employes.map((u)=>{return u.employe.toString()}),
                  extendedProps : eventEl,
                  id: eventEl.id.toString()
                }
              })
            )
          }
        )
      };
    });
    this.calendarOptions.eventDidMount  = (args) => {
      let eventId = args.event.id;
      let userId = args.el.closest("[data-resource-id]").getAttribute("data-resource-id");
      let fcEvent = args.event.extendedProps;
      let employe = fcEvent.employes.find((e)=>e.employe == userId);
      let string = '<div style="    display: flex;\n' +
        '    align-items: center;\n' +
        '    width: 100%;\n' +
        '    padding: 5px 0;\n' +
        '    margin-left: 2px;\n' +
        '    justify-content: center;\n' +
        '    background: #b5d2f7;">'+fcEvent.typeEvent+'</div>';

      args.el.style.background = "none";
      args.el.style.border = "none";
      args.el.innerHTML = string;
    }
  }

  refreshResources(){
    this.calendarComponent.getApi().refetchEvents();
  }

  getBateaux(){
    return this.bateauService.bateauxHttp().then((bateaux)=>{
      this.bateaux = bateaux;
    });
  }

  getUsers(){
    return this.userService.usersHttp().then((users)=>{
      this.users = users;
    });
  }

}
