import {Injectable} from '@angular/core';
import {Bateau} from "../models/Bateau";
import {HttpClient} from "@angular/common/http";
import {LogService} from "./Log.service";
import {API} from "../enums/API";
import {Ligne} from "../models/Ligne";
import {User} from "../models/User";
import {FcEvent} from "../models/FcEvent";
import * as moment from 'moment';
import 'moment/locale/fr';

@Injectable()

export class UserService {
    public users : User[];
    public user : User;
    constructor(private http: HttpClient,
                private logService: LogService) {
    }

    usersHttp() : Promise<User[]> {
        this.logService.logService("user.service.usersHttp()");
        return new Promise<User[]>((resolve,reject) => {
            this.http.get(API.GetUsersUrl(),{params:{per_page:'100'}}).toPromise()
                .then((data: User[]) => {
                    this.logService.logHttpCallback(API.GetUsersUrl(),data);
                    this.users=data;
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetUsersUrl());
                    reject();
                });
        });
    }

    getUserHttp(id:number) : Promise<User> {
        this.logService.logService("user.service.getUserHttp()");
        return new Promise<User>((resolve,reject) => {
            this.http.get(API.GetUsersUrl()+"/"+id).toPromise()
                .then((data: User) => {
                    this.logService.logHttpCallback(API.GetUsersUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetUsersUrl());
                    reject();
                });
        });
    }

    addUserHttp(user:User) : Promise<User> {
        this.logService.logService("user.service.addUsersHttp()");
        return new Promise<User>((resolve,reject) => {
            this.http.post(API.GetUsersUrl(),user).toPromise()
                .then((data: User) => {
                    this.logService.logHttpCallback(API.GetUsersUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetUsersUrl());
                    reject();
                });
        });

    }

    editUserHttp(user:User) : Promise<User> {
        this.logService.logService("user.service.editUsersHttp()");
        return new Promise<User>((resolve,reject) => {
            this.http.put(API.GetUsersUrl()+"/"+user.id,user).toPromise()
                .then((data: User) => {
                    this.logService.logHttpCallback(API.GetUsersUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetUsersUrl());
                    reject();
                });
        });
    }

    deleteUserHttp(user:User) : Promise<boolean> {
        this.logService.logService("user.service.deleteUsersHttp()");
        return new Promise<boolean>((resolve,reject) => {
            this.http.delete(API.GetUsersUrl()+"/"+user.id,{params:{reassign:'0',force:'true'}}).toPromise()
                .then((data: boolean) => {
                    this.logService.logHttpCallback(API.GetUsersUrl(),data);
                    resolve(data);
                })
                .catch(() => {
                    this.logService.logHttpCallbackError(API.GetUsersUrl());
                    reject();
                });
        });
    }

    populateUsersFromIds(ids:number[]):User[]{
        if(this.users !== undefined){
            return this.users.filter((u)=>ids.includes(u.id));
        }
        return [];
    }

    isAdmin(){
        return this.user?.userRole == 'admin';
    }

    isEmployeRef(event : FcEvent){
        return event.employeRef && event.employeRef == this.user.id;
    }

    isEmployeRefValidation(event : FcEvent){
        return this.isEmployeRef(event) && event.employeRefValidation;
    }

    isEmployeRefValidationLock(event : FcEvent){
        const now = moment();
        const eventEndDate = moment(event.dateEndEvent+' '+ event.heureFin, "DD/MM/YYYY hh:mm:ss");
        return this.isEmployeRefValidation(event) && eventEndDate.diff(now,'days')>1;
    }

    canShowCommentaire(){
      return this.user.canShowCommentaire || this.isAdmin();
    }
    canShowCommentaireRef(){
    return this.user.canShowCommentaireRef || this.isAdmin();
  }
}
