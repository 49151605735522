import {Entity} from "./Entity";
import {Port} from "./Port";
import {Bateau} from "./Bateau";

export class Ligne extends Entity {
    depart: Port|number;
    arrivee: Port|number;
    duree?:number;
    bateau_default?:Bateau|number;

}
