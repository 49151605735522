import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, switchMap, catchError } from 'rxjs/operators';
import { AuthService } from 'ngx-auth';
import {TokenStorageService} from "./TokenStorage.service";
import {API} from "../enums/API";


interface AccessData {
    token: string;
    id: string
}

@Injectable()
export class AuthenticationService implements AuthService {

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService
    ) {}

    /**
     * Check, if user already authorized.
     * @description Should return Observable with true or false values
     * @returns {Observable<boolean>}
     * @memberOf AuthService
     */
    public isAuthorized(): Observable < boolean > {
        return this.tokenStorage
            .getToken()
            .pipe(map(token => !!token));
    }

    /**
     * Get access token
     * @description Should return access token in Observable from e.g.
     * localStorage
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable < string > {
        return this.tokenStorage.getToken();
    }

    public getAccessUserId(): Observable < string > {
        return this.tokenStorage.getUserId();
    }

    /**
     * Function, that should perform refresh token verifyTokenRequest
     * @description Should be successfully completed so interceptor
     * can execute pending requests or retry original one
     * @returns {Observable<any>}
     */
    public refreshToken(): Observable <AccessData> {
        return this.tokenStorage
            .getToken()
            .pipe(
                switchMap((token: string) =>
                    this.http.post(API.GetTokenValidateUrl(), { token })
                ),
                tap((token: AccessData) => this.saveAccessData(token)),
                catchError((err) => {
                    this.logout();

                    return Observable.throw(err);
                })
            );
    }

    /**
     * Function, checks response of failed request to determine,
     * whether token be refreshed or not.
     * @description Essentialy checks status
     * @param {Response} response
     * @returns {boolean}
     */
    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        return response.status === 401
    }

    /**
     * Verify that outgoing request is refresh-token,
     * so interceptor won't intercept this request
     * @param {string} url
     * @returns {boolean}
     */
    public verifyTokenRequest(url: string): boolean {
        return url.endsWith('/refresh');
    }

    /**
     * EXTRA AUTH METHODS
     */

    public login(username:string, password:string): Observable<any> {
        return this.http.post(API.GetTokenUrl(), {username, password})
            .pipe(
                tap((data:any)=> {
                    if(data.statusCode === 200)
                    this.saveAccessData(data.data)
                }),
            );
    }

    /**
     * Logout
     */
    public logout(): void {
        this.tokenStorage.clear();
        location.reload(true);
    }

    /**
     * Save access data in the storage
     *
     * @private
     * @param {AccessData} data
     */
    private saveAccessData({ token, id}: AccessData) {
        this.tokenStorage.setToken(token,id)
    }

}
